import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useApproveOrRejectEstimateMutation } from '../../app/services/orders';
import { useState } from 'react';
import { toast } from 'react-toastify';

function Estimate() {
    const { estimateId } = useParams();
    const navigate = useNavigate();

    const [approveOrRejectEstimate] = useApproveOrRejectEstimateMutation();

    //Redux state
    const { orderHistory } = useSelector((state) => state.orders);
    const { storageToken } = useSelector((state) => state.auth);

    //Local state
    const [submitting, setSubmitting] = useState(false);

    const order = orderHistory?.find((order) => order.estimate?.id.toString() === estimateId);

    const estimateFileId = order?.estimate?.files.find((file) => file.fileType === 'ESTIMATE').id;

    const baseDownloadUrl = process.env.REACT_APP_API_BASE_URL
        ? process.env.REACT_APP_API_BASE_URL
        : '';

    return (
        <div className="max-w-xl space-y-4">
            <div>
                <h2 className="font-heading text-[44px]">GEM SLEEP ESTIMATE*</h2>
                <p className="text-sm">(*Estimate is valid for 30 days)</p>
            </div>
            {estimateFileId && (
                <a
                    className="btn-primary-small text-base lg:text-lg w-full"
                    href={`${baseDownloadUrl}/files/${estimateFileId}?storageToken=${storageToken}`}
                    download
                    target="_blank"
                    rel="noreferrer">
                    Download
                </a>
            )}

            <p>If you have questions regarding your estimate, please contact us</p>

            <button
                className="btn-primary-small text-base lg:text-lg w-full"
                disabled={submitting}
                onClick={async () => {
                    setSubmitting(true);
                    const approval = await approveOrRejectEstimate({
                        estimateId,
                        fileId: estimateFileId,
                        status: 'APPROVED',
                    }).unwrap();

                    if (approval) {
                        toast.success('Your estimate has been approved');
                        setSubmitting(false);
                        navigate('/dashboard');
                    } else {
                        toast.error('Something went wrong, please try again.');
                        setSubmitting(false);
                    }
                }}>
                APPROVE, SEND ME THE CPAP
            </button>
            <button
                disabled={submitting}
                className="btn-shell-small text-base lg:text-lg w-full"
                onClick={async () => {
                    setSubmitting(true);
                    const approval = await approveOrRejectEstimate({
                        estimateId,
                        fileId: estimateFileId,
                        status: 'REJECTED',
                    }).unwrap();

                    if (approval) {
                        setSubmitting(false);
                        navigate('/dashboard');
                    } else {
                        toast.error('Something went wrong, please try again.');
                        setSubmitting(false);
                    }
                }}>
                NOT AT THIS TIME
            </button>

            <p className="font-bold">Important Notes:</p>
            <ol className="lg:list-disc">
                {['PRIOR_AUTH_NEEDED', 'PRIOR_AUTH_AFTER_CONSULT'].includes(
                    order?.priorAuthStatus,
                ) && (
                    <li key="prior-auth-required">
                        (PA REQUIRED) Your insurance company requires we obtain a Prior
                        Authorization (PA) before your CPAP can ship, this process can take up to 15
                        business days. The PA will be submitted once we have approval on the
                        estimate.{' '}
                    </li>
                )}
                <li key="approval-notice">
                    By approving the estimate, you are agreeing to pay any patient balance due after
                    your claim has been processed, GEM will collect payment information. Payment
                    plans are available.
                </li>
            </ol>
        </div>
    );
}

export default Estimate;
