import { motion } from 'framer-motion';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetPayersQuery } from '../../../app/services/insurance';
import { toggleModal } from '../../../features/ui/uiSlice';
import { dateToISOString, formatDate, overlayVariants } from '../../../utils/constants';
import { insuranceUpdateSchema } from '../../../utils/schemas';
import InsuranceForm, { FormType } from '../InsuranceForm';
import { useCheckoutContext } from '../../Checkout/CheckoutContext';
import { useInsuranceContext } from '../InsuranceContext';

const NotFound = ({ updatePatientCurrentInsurance }) => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const { data: payers } = useGetPayersQuery();
    const [errorMessage, setErrorMessage] = useState(null);

    //context
    const { skipInsurance } = useCheckoutContext();
    const { insurance } = useInsuranceContext();

    const payer = payers.find((p) => {
        if (insurance && insurance.payerId === p.id) {
            return true;
        } else {
            return false;
        }
    });

    const initialValues = {
        payer: payer ?? null,
        memberId: insurance?.memberId || '',
        groupId: insurance?.groupId || '',
        patientFirstName: user?.firstName ?? '',
        patientLastName: user?.lastName ?? '',
        patientDob: user ? formatDate(user.birthdate) : '',
        policyHolderFirstName: user?.firstName ?? '',
        policyHolderLastName: user?.lastName ?? '',
        policyHolderDob: user ? formatDate(user.birthdate) : '',
        policyHolderRelationship: insurance?.policyHolderRelationship || 'Self',
    };

    const insuranceSubmitHandler = async (values) => {
        const changedValues = {};

        Object.keys(values)
            .filter((key, i) => values[key] !== initialValues[key])
            .forEach((k) => {
                if (k === 'policyHolderDob' || k === 'patientDob') {
                    changedValues[k] = dateToISOString(values[k]);
                } else if (k === 'payer') {
                    changedValues[k] = values[k];
                } else {
                    changedValues[k] = values[k];
                }
            });
        // if they try again without modification, we need to set the insurance status back to review
        const updateInsuranceResult = await updatePatientCurrentInsurance(
            user.id,
            insurance?.id,
            changedValues,
        );

        const updateSuccess = updateInsuranceResult?.status === 'success';
        if (!updateSuccess) {
            setErrorMessage(updateInsuranceResult.data?.message);
        }
    };

    return (
        <motion.div
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={overlayVariants}
            className="space-y-5">
            <h5 className="font-heading text-4xl font-bold lg:text-5xl">Hmm. We can't find you.</h5>
            <p>
                <button
                    className="link"
                    onClick={() => {
                        dispatch(toggleModal('insuranceDetailHelp'));
                    }}>
                    Need help?
                </button>
            </p>

            {errorMessage && <p className="text-lg font-bold text-red">{errorMessage}</p>}

            {payers && (
                <InsuranceForm
                    buttonCopy={`Try Again`}
                    user={user}
                    formType={FormType.RECHECK}
                    targetInsurance={insurance}
                    validationSchema={insuranceUpdateSchema}
                    onSubmit={insuranceSubmitHandler}
                />
            )}

            <p className="text-center">
                <button
                    className="btn-shell"
                    onClick={() => {
                        skipInsurance();
                    }}>
                    Continue without insurance
                </button>
            </p>
        </motion.div>
    );
};

export default NotFound;
