import { usePostHog } from 'posthog-js/react';
import { useEffect } from 'react';
import { FiArrowUpRight } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GemSleepPathLine } from '../../components/Diagnosis';
import { nextQuestion, reset, setAnswer } from '../../features/assessment/assessmentSlice';

const AssessmentHome = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const posthog = usePostHog();
    const isSleepSupport = window.location.host.includes('sleepsupport');

    const previouslyDiagnosedClickHandler = () => {
        // save previously diagnosed status on click
        dispatch(setAnswer({ answer: true, answerKey: 'previouslyDiagnosedWithSleepApnea' }));
        dispatch(nextQuestion('previouslyDiagnosedWithSleepApnea'));

        posthog?.capture('Started Stop Bang', { entryPoint: 'previouslyDiagnosedWithSleepApnea' });

        posthog?.capture('Answered a Question', {
            question: 'previouslyDiagnosedWithSleepApnea',
            answer: true,
        });

        navigate('/assessment');
    };

    useEffect(() => {
        dispatch(reset());
    }, [dispatch]);

    return (
        <div className="container text-gray-light">
            <div className="relative flex-col items-center text-center">
                <div className="relative mx-auto max-w-4xl lg:flex-1">
                    <div className="hidden xl:block">
                        <GemSleepPathLine
                            pathLabels={[
                                { text: 'Sleep apnea assessment', centered: true },
                                { text: 'Sleep Study', centered: true },
                                { text: 'The right treatment', centered: true },
                                { text: 'Personalized support', centered: true },
                            ]}
                            stepsCompleted={0}
                        />
                    </div>
                    <h1 className="mt-5 font-heading text-5xl text-gray-light lg:mt-0 lg:mb-3 lg:text-6xl lg:leading-[1]">
                        Think you might have <span className="text-white">sleep apnea?</span>
                    </h1>
                    <span className="mb-8 mt-4 block text-sm xs:text-base leading-normal text-gray-light lg:mb-12 lg:mr-6 lg:text-[21px]">
                        {isSleepSupport
                            ? 'GEM SLEEP is a virtual sleep clinic for obstructive sleep apnea diagnosis and care. Get at-home testing, start treatment, and receive personalized coaching – all from home. Even if you’ve got a CPAP that’s collecting dust in your closet, we can help you get up and running again. Take the SLEEP QUIZ to create an account and get started.'
                            : 'GEM SLEEP is a virtual sleep clinic. Take our one-minute sleep quiz, which will help us determine your risk of Obstructive Sleep Apnea (OSA) and recommend the best next step. If home sleep testing is recommended, you can get started right away.'}
                    </span>
                </div>
                <div className="mx-auto flex max-w-3xl items-center">
                    <button
                        className="btn-primary w-full text-xl"
                        onClick={() => {
                            posthog?.capture('Started Stop Bang', {
                                entryPoint: 'takeSleepQuizButton',
                            });

                            navigate('/assessment');
                        }}>
                        Take sleep quiz
                    </button>
                </div>

                <div className="mx-auto flex max-w-4xl items-center mb-12">
                    <button
                        data-attr="start-assessment-previously-diagnosed"
                        className="flex w-full items-center justify-between mt-8 p-2 xs:p-4 md:mt-16 md:p-8 rounded-3xl bg-[#1a1c27] hover:bg-[#14161f] border-[#b2b6d966] border-[1px]"
                        onClick={() => {
                            previouslyDiagnosedClickHandler();
                        }}>
                        <span
                            data-attr="start-assessment-previously-diagnosed"
                            className="text-sm font-bold text-purple-lightest md:text-xl">
                            Already diagnosed with sleep apnea?
                        </span>
                        <span
                            data-attr="start-assessment-previously-diagnosed"
                            className="tracking-widest flex items-center font-heading text-xl text-[19px] text-white">
                            <span
                                data-attr="start-assessment-previously-diagnosed"
                                className="hidden md:inline">
                                Start Here
                            </span>{' '}
                            <b className="inline-block w-[3rem] h-[3rem] bg-[#726AD3] rounded-[50%] ml-4 relative">
                                <FiArrowUpRight
                                    data-attr="start-assessment-previously-diagnosed"
                                    className="absolute inset-0 m-auto"
                                />
                            </b>
                        </span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AssessmentHome;
