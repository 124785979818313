import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setAnswer, nextQuestion } from '../../../features/assessment/assessmentSlice';
import { motion, useAnimationControls } from 'framer-motion';
import { usePostHog } from 'posthog-js/react';

const AnswerGroup = ({ questionData }) => {
    const [noDisabled, setNoDisabled] = useState(false);
    const [yesDisabled, setYesDisabled] = useState(false);
    const noControls = useAnimationControls();
    const yesControls = useAnimationControls();
    const dispatch = useDispatch();
    const posthog = usePostHog();

    const videoEndHandler = (answer) => {
        dispatch(setAnswer({ answer: answer === 'yes', answerKey: questionData.answerKey }));
        dispatch(nextQuestion(questionData.answerKey));

        posthog?.capture('Answered a Question', {
            question: questionData.answerKey,
            answer: answer,
        });
    };

    const playAnswer = (answer) => {
        if (answer === 'no') {
            noControls
                .start({
                    x: ['100%', '0%', '-100%'],
                    transition: { ease: 'easeInOut', duration: 0.85 },
                })
                .then(() => {
                    setYesDisabled(true);
                    videoEndHandler(answer);
                });
        }

        if (answer === 'yes') {
            yesControls
                .start({
                    x: ['-100%', '0%', '100%'],
                    transition: { ease: 'easeInOut', duration: 0.85 },
                })
                .then(() => {
                    setNoDisabled(true);
                    videoEndHandler(answer);
                });
        }
    };

    return (
        <div className="mx-auto max-w-xl">
            <button
                className="group relative w-1/2 overflow-hidden border border-purple-medium text-xl font-semibold transition duration-300 ease-out-quad hover:bg-purple-medium/20 active:bg-purple-medium/20 disabled:opacity-25 sm:text-5xl h-[175px] sm:h-[288px]"
                data-attr={`${questionData.answerKey}-no`}
                onClick={() => {
                    playAnswer('no');
                }}
                disabled={noDisabled}>
                <span
                    data-attr={`${questionData.answerKey}-no`}
                    style={{ opacity: yesDisabled ? 0 : 1 }}
                    className="absolute-center transition duration-500 ease-out-quad group-hover:scale-125">
                    {questionData.yesNoOverride ? questionData.yesNoOverride[0] : 'No'}
                </span>
                <motion.div
                    className="pointer-events-none absolute top-0 left-0 h-full w-full translate-x-full bg-salmon"
                    animate={noControls}></motion.div>
            </button>
            <button
                className="group relative w-1/2 -translate-x-[1px] overflow-hidden border border-purple-medium text-xl font-semibold transition duration-300 ease-out-quad hover:bg-purple-medium/20 active:bg-purple-medium/20 disabled:opacity-25 sm:text-5xl h-[175px] sm:h-[288px]"
                data-attr={`${questionData.answerKey}-yes`}
                onClick={() => {
                    playAnswer('yes');
                }}
                disabled={yesDisabled}>
                <span
                    data-attr={`${questionData.answerKey}-yes`}
                    style={{ opacity: noDisabled ? 0 : 1 }}
                    className="absolute-center transition duration-500 ease-out-quad group-hover:scale-125">
                    {questionData.yesNoOverride ? questionData.yesNoOverride[1] : 'Yes'}
                </span>
                <motion.div
                    className="pointer-events-none absolute top-0 left-0 h-full w-full -translate-x-full bg-green-400"
                    animate={yesControls}></motion.div>
            </button>
        </div>
    );
};

export default AnswerGroup;
