import { useRef, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ReactGA from 'react-ga4';
import {
    reset,
    setPartner,
    setSleepStudyStatus,
    setAnswer,
    nextQuestion,
} from '../../features/assessment/assessmentSlice';
import { Accordion } from '../../components';
import playButtonImgSrc from '../../images/gem-pitch-video-btn.png';
import {
    stepsIfRight,
    stepsIfRightDentalPractice,
    otherThingsItems,
    otherThingsDentalPracticeItems,
    lerpColor,
} from '../../utils/constants';
import YoutubeVideo from '../../components/YoutubeVideo/YoutubeVideo';
import { useGetOrganizationQuery } from '../../app/services/insurance';
import { usePostHog } from 'posthog-js/react';

const AssessmentDentalReferral = () => {
    const { dentalPracticeId } = useParams();
    const howItWorksRef = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const posthog = usePostHog();
    const { data: dentalAttribution } = useGetOrganizationQuery(dentalPracticeId, {
        skip: !dentalPracticeId,
    });

    const beginClickHandler = (diagnosisStatus) => {
        // save previously diagnosed status on click
        dispatch(
            setAnswer({ answer: diagnosisStatus, answerKey: 'previouslyDiagnosedWithSleepApnea' }),
        );
        dispatch(nextQuestion('previouslyDiagnosedWithSleepApnea'));
        ReactGA.event({
            category: 'User',
            action: 'Answered a Question',
            label: `previouslyDiagnosedWithSleepApnea_${diagnosisStatus}`,
        });

        posthog?.capture('Started Stop Bang', { entryPoint: 'dentalReferral' });

        posthog?.capture('Answered a Question', {
            question: 'previouslyDiagnosedWithSleepApnea',
            answer: diagnosisStatus,
        });

        navigate('/assessment');
    };

    useEffect(() => {
        dispatch(reset());
    }, [dispatch]);

    useEffect(() => {
        if (!dentalPracticeId) return;

        dispatch(setPartner({ id: +dentalPracticeId, type: 'DENTAL' }));
    }, [dentalPracticeId, dispatch]);

    return (
        <div className="container text-gray-light">
            <div className="relative lg:flex lg:items-center">
                <div className="relative lg:flex-1">
                    <h1 className="-mt-5 font-heading text-5xl text-white lg:mt-0 lg:mb-3 lg:text-9xl lg:leading-[1]">
                        The path to better sleep.
                    </h1>
                    <span className="mb-8 block text-xl text-purple lg:mb-12 lg:text-[28px] lg:mr-6">
                        Answer a few questions so we can get you on the right path.
                    </span>
                </div>

                <div
                    className="mt-30 z-0 mb-20 lg:order-2 lg:mx-0 lg:mb-40 lg:mt-20 lg:h-auto lg:w-2/4"
                    style={{
                        backgroundColor: 'rgba(255,255,255,0.05)',
                        padding: '4rem 3rem',
                    }}>
                    {dentalAttribution && (
                        <>
                            <p className="mb-4 max-w-2xl leading-normal text-white lg:mb-6 lg:text-xl">
                                {dentalAttribution.name} has partnered with GEM SLEEP to support the
                                next steps in your treatment all from the comfort of your home.
                            </p>
                            <p className="mb-4 max-w-2xl leading-normal text-white lg:mb-6 lg:text-xl">
                                Select where you are at in your journey! We will guide you through
                                the next steps!
                            </p>
                        </>
                    )}

                    {!dentalAttribution && (
                        <>
                            <p className="mb-4 max-w-2xl font-bold leading-normal text-white lg:mb-6 lg:text-xl">
                                Think you might have sleep apnea? We can help.
                            </p>
                            <div className="start-actions flex flex-col">
                                <button
                                    className="block btn-primary mb-6 lg:mb-4"
                                    onClick={() => {
                                        beginClickHandler(false);
                                    }}>
                                    Take Sleep Quiz
                                </button>
                                <button
                                    className="!bg-[#00aa6333] !hover:bg-[#00aa631a] block btn-primary mb-6 lg:mb-12 opacity-80"
                                    onClick={() => {
                                        beginClickHandler(true);
                                    }}>
                                    I have already been diagnosed
                                </button>
                            </div>
                        </>
                    )}

                    {dentalAttribution && (
                        <div className="mb-6 flex max-w-md flex-col space-y-4 lg:mb-12">
                            <button
                                className="btn-primary"
                                onClick={() => {
                                    navigate('/assessment');
                                }}>
                                Need a Home Sleep Test
                            </button>
                            <button
                                className="btn-primary"
                                onClick={() => {
                                    dispatch(setSleepStudyStatus('HST_RECEIVED_IN_OFFICE'));
                                    navigate('/assessment');
                                }}>
                                Received a Home Sleep Test in Office
                            </button>
                        </div>
                    )}

                    <p className="max-w-3xl text-sm leading-6 tracking-tight lg:text-lg">
                        Already a GEM SLEEP member?{' '}
                        <Link
                            to="/sign-in"
                            className="border-b-2 border-green-400 font-bold text-white">
                            Sign in
                        </Link>
                    </p>
                </div>
            </div>

            <div className="relative z-0">
                <YoutubeVideo
                    videoId="HyFBqJ5jAH4"
                    videoTitle={`Dr. Howell Gem Introduction`}
                    playButton={<img src={playButtonImgSrc} alt="Play Video" />}
                />
            </div>
            <section className="py-24 lg:py-36" ref={howItWorksRef}>
                <h3 className="flex items-center font-heading text-lg tracking-wide lg:text-2xl">
                    <span>How it works</span>
                    <span className="ml-2 h-[1px] w-24 bg-gray-light lg:w-48"></span>
                </h3>
                <h2 className="mb-14 font-heading text-[44px] text-white lg:mb-24 lg:text-7xl">
                    {dentalAttribution
                        ? '3 Simples Steps to BETTER SLEEP:'
                        : '3 simple steps to better sleep'}
                </h2>
                <p className="mb-6 lg:mb-12">
                    {dentalAttribution
                        ? 'Follow these 3 simple steps to get started:'
                        : 'Follow these steps to get started and see if GEM SLEEP is right for you:'}
                </p>
                <ul className="mb-14 space-y-14 text-center lg:mb-24 lg:flex lg:items-start lg:justify-center lg:space-y-0 lg:space-x-20">
                    {(dentalAttribution
                        ? stepsIfRightDentalPractice(dentalAttribution.name)
                        : stepsIfRight
                    ).map((step, idx) => (
                        <li
                            key={`step-${idx}`}
                            className="mx-auto max-w-xs lg:mx-0 lg:max-w-none lg:flex-1">
                            <p
                                className="outline-text font-heading text-9xl"
                                style={{
                                    color: lerpColor(
                                        '#ffffff',
                                        '#7d72cc',
                                        idx / (stepsIfRight.length - 1),
                                    ),
                                }}>{`.0${idx + 1}`}</p>
                            <p className="text-white lg:text-xl">{step}</p>
                        </li>
                    ))}
                </ul>
            </section>

            <section className="py-32">
                <h3 className="mx-auto mb-8 flex max-w-4xl items-center justify-end font-heading text-lg tracking-wide lg:mb-20 lg:text-2xl">
                    <span className="mr-2 h-[1px] w-24 bg-gray-light lg:w-48"></span>
                    <span>Other things to know</span>
                </h3>
                <Accordion
                    items={dentalAttribution ? otherThingsDentalPracticeItems : otherThingsItems}
                    className="mx-auto max-w-4xl text-white"
                />
            </section>

            {dentalAttribution && (
                <p className="mx-auto mb-8 max-w-4xl text-sm lg:text-lg">
                    {dentalAttribution.name} <br />
                    {dentalAttribution.address} <br />
                    {`${dentalAttribution.city}, ${dentalAttribution.state} ${dentalAttribution.zip}`}{' '}
                    <br />
                    <a href={`mailto:${dentalAttribution.email}`}>{dentalAttribution.email}</a>{' '}
                    <br />
                    <a href={`tel:+1${dentalAttribution.phone.replace(/[- )(]/g, '')}`}>
                        {dentalAttribution.phone}
                    </a>
                </p>
            )}

            {dentalAttribution && (
                <p className="mx-auto mb-8 max-w-4xl text-xs italic">
                    *Dental oral appliance therapy is not for everyone, depending on the severity of
                    your sleep apnea you may not qualify. GEM SLEEP will provide a treatment
                    recommendation that works for you.
                </p>
            )}
        </div>
    );
};

export default AssessmentDentalReferral;
