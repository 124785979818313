import { addDays } from 'date-fns';
import { api } from '../auth';
import { orderByObjectKeysForReferrals } from '../../../utils/constants';

const referralsAPI = api.injectEndpoints({
    endpoints: (builder) => ({
        getPaginatedReferrals: builder.query({
            query: ({
                dateSearch,
                startDate,
                endDate,
                referralStatus,
                organizationId,
                orderByKey,
                orderDesc,
                skip,
                userId,
            }) => {
                const whereObject = {
                    ...(userId && { userId: userId }),
                    ...(referralStatus && { referralStatus: referralStatus }),
                    ...(organizationId && { organizationId }),
                };

                if (dateSearch && startDate) {
                    whereObject[dateSearch] = {
                        gte: new Date(startDate), // Day selected
                        lt: endDate
                            ? addDays(new Date(endDate), 1)
                            : addDays(new Date(startDate), 1), // Next day selected if no end date specified
                    };
                }

                const orderByObject = orderByObjectKeysForReferrals.get(orderByKey);
                orderByObject[Object.keys(orderByObject)[0]].sort = orderDesc ? 'desc' : 'asc';

                return {
                    url: '/referrals/list',
                    method: 'POST',
                    body: {
                        skip: skip.toString(),
                        take: '10',
                        where: whereObject ? JSON.stringify({ ...whereObject }) : null,
                        orderBy: JSON.stringify(orderByObject),
                    },
                };
            },
            providesTags: (result) => ['ReferralList'],
        }),
        updateReferral: builder.mutation({
            query: ({ id, data }) => ({
                url: `/referrals/${id}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['ReferralList', 'User'],
        }),
    }),
    overrideExisting: false,
});

export const { useGetPaginatedReferralsQuery, useUpdateReferralMutation } = referralsAPI;
