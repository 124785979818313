import { ReferralsList } from '../../components/Admin/Referrals';

function Referrals() {
    return (
        <>
            <h2 className="mb-5 pt-6 flex items-center justify-between">
                <span className="font-heading text-5xl">Referrals</span>
            </h2>
            <ReferralsList />
        </>
    );
}

export default Referrals;
