import { useEffect, useState } from 'react';
import { useGetAdminUsersQuery } from '../../app/services/admin/admin';
import {
    useAddToPoolMutation,
    useGetCareTeamPoolsQuery,
    useRemoveFromPoolMutation,
} from '../../app/services/admin/careteam';
import { Modal } from '../../components';

const PoolComponent = ({ pool, adminUserList, handleAdminClick, getMemberName }) => {
    if (!adminUserList) {
        return <p>Loading administrators...</p>;
    }
    return (
        <div className="rounded-xl bg-gray-darker p-5 mb-4">
            <div className="flex items-start">
                <p className="text-xl font-bold">{pool.name}</p>
            </div>
            <ul className="mt-2">
                {pool.poolMembers.map((member) => (
                    <li key={member.id} className="ml-4">
                        <button
                            onClick={() => handleAdminClick(member.memberId, pool)}
                            className="border-b-2 border-green-400 font-bold">
                            {getMemberName(member.memberId)}
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

const CareTeam = () => {
    const adminUserQuery = [
        { isActive: true, userRoles: { some: { role: 'MD' } } },
        { isActive: true, userRoles: { some: { role: 'COACH' } } },
    ];
    const { data: adminUsers, isLoading: membersLoading } = useGetAdminUsersQuery(adminUserQuery);
    const { data: pools, isLoading: poolsLoading } = useGetCareTeamPoolsQuery();
    const [addToPool] = useAddToPoolMutation();
    const [removeFromPool] = useRemoveFromPoolMutation();

    const [editingMemberOpen, setEditingMemberOpen] = useState(false);
    const [currentAdmin, setCurrentAdmin] = useState(null);
    const [unassignedMembers, setUnassignedMembers] = useState([]);
    const [initialPool, setInitialPool] = useState(null);
    const [selectedPool, setSelectedPool] = useState(null);
    const [memberInPool, setMemberInPool] = useState(false);
    const adminUserList = adminUsers?.results;

    const isMemberInPool = (memberId, poolId, pools) => {
        const pool = pools.find((p) => p.id === poolId);
        return pool ? pool.poolMembers.some((member) => member.memberId === memberId) : false;
    };

    useEffect(() => {
        setUnassignedMembers(getUnassignedMembers(adminUserList, pools));
    }, [pools, adminUserList]);

    useEffect(() => {
        if (currentAdmin && selectedPool && pools) {
            const isInPool = isMemberInPool(currentAdmin, selectedPool, pools);
            setMemberInPool(isInPool);
        }
    }, [currentAdmin, selectedPool, pools]);

    const getMemberName = (memberId) => {
        const member = adminUserList.find((user) => user.id === memberId);
        return member ? `${member.firstName} ${member.lastName} ` : 'Unknown Member';
    };

    const getUnassignedMembers = (members, pools) => {
        if (!members || !pools) {
            return [];
        }
        const unassigned = [];
        let inPool = false;
        for (const m of members) {
            inPool = false;
            for (const pool of pools) {
                if (pool.poolMembers.some((poolMember) => poolMember.memberId === m.id)) {
                    inPool = true;
                    break;
                }
            }
            //if the member isn't in any pools they need to be added to the unassiged 'pool'
            if (!inPool) {
                unassigned.push(m.id);
            }
        }
        return unassigned;
    };

    const handleAdminClick = (id, pool) => {
        setCurrentAdmin(id);

        if (unassignedMembers.includes(id)) {
            setInitialPool('unassign');
            setSelectedPool('unassign');
        } else {
            setInitialPool(pool.id);
            setSelectedPool(pool.id);
        }
        setEditingMemberOpen(true);
    };

    const editAdminClick = (id, newPool) => {
        if (newPool === 'unassign') {
            for (const pool of pools) {
                if (pool.poolMembers.some((poolMember) => poolMember.memberId === id)) {
                    removeFromPool({
                        poolId: pool.id,
                        memberId: id,
                    });
                }
            }
        } else {
            if (memberInPool) {
                removeFromPool({
                    poolId: newPool,
                    memberId: id,
                });
            } else {
                addToPool({
                    poolId: newPool,
                    memberId: id,
                });
            }
        }
    };

    const handleChange = (e) => {
        if (e.target.value !== 'unassign') {
            const intValue = parseInt(e.target.value);
            setSelectedPool(intValue);
        } else {
            setSelectedPool(e.target.value);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        editAdminClick(currentAdmin, selectedPool);
        setEditingMemberOpen(false);
    };

    if (poolsLoading || !pools || membersLoading) {
        return <div>Loading pools...</div>;
    }

    return (
        <>
            <div className="py-6">
                <h2 className="mb-5 flex items-center justify-between">
                    <span className="font-heading text-5xl">Care Teams </span>
                </h2>
                <div>
                    {!poolsLoading && pools?.length > 0 ? (
                        <>
                            {pools.map((pool) => (
                                <PoolComponent
                                    pool={pool}
                                    adminUserList={adminUserList}
                                    handleAdminClick={handleAdminClick}
                                    getMemberName={getMemberName}
                                />
                            ))}
                        </>
                    ) : (
                        <p className="text-xl">Loading pools...</p>
                    )}
                    <div className="rounded-xl bg-gray-darker p-5 mb-4">
                        <div className="flex items-start">
                            <p className="text-xl font-bold">Unassigned Care Team Members</p>
                        </div>
                        <ul className="mt-2">
                            {unassignedMembers.map((member) => (
                                <li key={member} className="ml-4">
                                    <button
                                        onClick={() => handleAdminClick(member, unassignedMembers)}
                                        className="border-b-2 border-green-400 font-bold">
                                        {getMemberName(member)}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            {currentAdmin && (
                <Modal
                    showClose={true}
                    isOpen={editingMemberOpen}
                    closeHandler={() => {
                        setEditingMemberOpen(false);
                    }}
                    className="bg-white p-8 rounded-xl shadow-lg">
                    <div className="Edit Pool Modal">
                        <section>
                            <h2 className="font-heading text-3xl mb-4">
                                Edit {getMemberName(currentAdmin)}
                            </h2>
                            <form onSubmit={handleSubmit}>
                                <div className="relative flex items-center space-x-4">
                                    <select
                                        name="pool"
                                        value={selectedPool}
                                        onChange={handleChange}
                                        className="flex-1 min-w-[250px] p-2 border border-gray-300 rounded-md bg-white appearance-none text-base leading-6 focus:outline-none focus:ring-2 focus:ring-blue-500">
                                        {pools.map((pool) => (
                                            <option key={pool.id} value={pool.id}>
                                                {pool.name}
                                            </option>
                                        ))}
                                        <option value="unassign">Unassign</option>
                                    </select>
                                    {selectedPool === 'unassign' && initialPool !== 'unassign' ? (
                                        <button className="btn-primary-small" type="submit">
                                            Unassign
                                        </button>
                                    ) : selectedPool !== 'unassign' && memberInPool ? (
                                        <button className="btn-primary-small" type="submit">
                                            Remove
                                        </button>
                                    ) : selectedPool !== 'unassign' ? (
                                        <button className="btn-primary-small" type="submit">
                                            Add
                                        </button>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </form>
                        </section>
                    </div>
                </Modal>
            )}
        </>
    );
};
export default CareTeam;
