import Dialog from '../../Dialog/Dialog';

import { useGetOrderChangesHistoryQuery } from '../../../app/services/admin/orders';
import { convertEnumValueToDisplayString, formatDate } from '../../../utils/constants';

import { TiArrowRight } from 'react-icons/ti';

function OrderChanges({ orderId, setOrderChangesOpen }) {
    const { data: orderChanges, isLoading } = useGetOrderChangesHistoryQuery(orderId);

    return (
        <Dialog setOpen={setOrderChangesOpen}>
            <div className="py-2 bg-purple-dark">
                {isLoading ? (
                    <p className="text-white font-bold text-xl p-10">Loading...</p>
                ) : orderChanges?.length === 0 ? (
                    <p className="text-white font-bold text-xl p-10">
                        No order changes have been recorded.
                    </p>
                ) : (
                    <div className="min-w-[1000px] max-h-[90vh] overflow-y-auto text-gray">
                        <div className="grid grid-cols-[100px_150px_1fr_200px] p-2 sticky">
                            <p className="font-bold">Date</p>
                            <p className="font-bold">Type</p>
                            <p className="text-center font-bold">Change</p>
                            <p className="font-bold">By</p>
                        </div>
                        {orderChanges.map((change, index) => (
                            <div
                                className="grid grid-cols-[100px_150px_1fr_200px] text-sm hover:bg-gray-darker p-2 text-white items-center"
                                key={change.id}>
                                <div
                                    className="daisy-tooltip text-left daisy-tooltip-right"
                                    data-tip={new Date(change.createdAt)}>
                                    <p>{formatDate(change.createdAt)}</p>
                                </div>

                                <p>{convertEnumValueToDisplayString(change.changeType)}</p>
                                <div className="grid grid-cols-[1fr_30px_1fr] items-center px-2">
                                    <p className="text-right">{change.changedFrom}</p>
                                    <TiArrowRight className="h-6 w-6" />
                                    <p className="flex">{change.changedTo}</p>
                                </div>
                                <p>
                                    {change.changedBy?.firstName + ' ' + change.changedBy?.lastName}
                                </p>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </Dialog>
    );
}

export default OrderChanges;
