import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateMeMutation } from '../../app/services/auth';
import {
    useCreateNikoOrderMutation,
    useCreatePatientInsuranceMutation,
    useUpdatePatientInsuranceMutation,
} from '../../app/services/insurance';
import { setConsultRequired, toggleInsurance } from '../../features/cart/cartSlice';
import { ReactComponent as LeftArrow } from '../../images/left-arrow.svg';
import Spinner from '../Spinner/Spinner';
import {
    AddInsuranceInfo,
    CheckCoverage,
    CurrentInsuranceInfo,
    Eligible,
    NotEligible,
    NotFound,
    PaymentChoice,
    UploadCardQuestion,
    UploadInsuranceCard,
} from './InsuranceCheckComponents';
import { useCheckoutContext } from '../Checkout/CheckoutContext';
import { useInsuranceContext } from './InsuranceContext';

const MAXIMUM_WAIT_TIME = 30000;

const InsuranceCheck = ({ onInsuranceStepsComplete }) => {
    //steps in the insurance check, and information on whether to allow them to navigate back to the beginning on this step
    const steps = {
        'check-coverage': { backButtonActive: false },
        'checking-eligibility': { backButtonActive: false },
        'insurance-card': { backButtonActive: true },
        'insurance-card-question': { backButtonActive: true },
        'insurance-details': { backButtonActive: true },
        'insurance-review': { backButtonActive: true },
        'is-eligible': { backButtonActive: false },
        'not-eligible': { backButtonActive: false },
        'not-found': { backButtonActive: true },
        'payment-choice': { backButtonActive: false },
    };

    //context
    const { checkoutStep, setCheckoutStep } = useCheckoutContext();
    const { insurance, getInsurance } = useInsuranceContext();

    //redux state selectors
    const { user } = useSelector((state) => state.auth);
    const { stateRequiredTelehealth, cpapInCart, hstInCart } = useSelector((state) => state.cart);

    // local state context
    const [ready, setReady] = useState(false); // flag that determines if the insurance container is ready to render
    const [eligibilityAttempts, setEligibilityAttempts] = useState(0); // keeps track of eligibility attempts

    //query hooks
    const dispatch = useDispatch();
    const [createPatientInsurance] = useCreatePatientInsuranceMutation();
    const [createNikoOrder] = useCreateNikoOrderMutation();
    const [updatePatientInsurance] = useUpdatePatientInsuranceMutation();
    const [updateMe] = useUpdateMeMutation();

    const checkInsuranceEligibility = async () => {
        try {
            const ins = await getInsurance(user.id).unwrap();
            switch (ins.insuranceStatus?.toLowerCase()) {
                case 'active':
                    //Marks cart as using insurance
                    dispatch(toggleInsurance(true));
                    setCheckoutStep('insurance-card-question');
                    break;
                case 'failed':
                    setEligibilityAttempts((previous) => previous + 1);
                    if (eligibilityAttempts >= 1) {
                        setCheckoutStep('insurance-card-question');
                        dispatch(toggleInsurance(true));
                    } else {
                        setCheckoutStep('not-found');
                    }
                    break;
                default:
                    break;
            }
        } catch (err) {
            setCheckoutStep('not-found');
        }
    };

    const addPatientInsurance = async (insuranceData) => {
        const { data: newInsurance, error } = await createPatientInsurance({
            userId: user.id,
            data: {
                payerId: insuranceData.payer.id,
                memberId: insuranceData.memberId,
                groupId: insuranceData.groupId,
                otherPayerName: insuranceData.otherPayerName,
                multiplan: false,
            },
        });

        if (error) {
            //return error to form
            return { status: 'error', data: error.data };
        }
        // this is a hack, essentially, if eligibility check is not supported
        // the insurance object is undefined for the next step and we are using the lazy query
        // to support the polling
        await getInsurance(user.id);
        if (checkIfElibigilityCheckRequired(newInsurance)) {
            //dummy order, should be replaced with Niko products from the cart eventually
            const { error: orderError } = await createNikoOrder({
                products: [
                    {
                        productId: 'A89JM47PS23LN56KG42C',
                        count: 1, // can we use 0 here for a placeholder?
                        notes: 'Creating Order to check insurance eligibility',
                    },
                ],
            });

            if (orderError) {
                return;
            }
            setCheckoutStep('checking-eligibility');
        }
    };

    const updatePatientCurrentInsurance = async (userId, insuranceId, changedValues) => {
        // if patientFirstName or patientLastName changed or patientDob, update user
        if (
            changedValues.patientFirstName ||
            changedValues.patientLastName ||
            changedValues.patientDob
        ) {
            const patientPayload = {
                firstName: changedValues.patientFirstName,
                lastName: changedValues.patientLastName,
                birthdate: changedValues.patientDob,
            };
            const updatePatientResult = await updateMe(patientPayload);
            if (updatePatientResult.error) {
                return { status: 'error', data: updatePatientResult.error.data };
            }
        }

        const insurancePayload = {
            userId,
            insuranceId,
            data: { ...changedValues, insuranceStatus: 'Review' },
        };

        if (changedValues.payer) {
            insurancePayload.data.payerId = changedValues.payer.id;
            delete insurancePayload.data.payer;
        }

        const { data: updatedInsurance, error } = await updatePatientInsurance(insurancePayload);
        if (error) {
            return { status: 'error', data: error.data };
        }

        if (checkIfElibigilityCheckRequired(updatedInsurance)) {
            setCheckoutStep('checking-eligibility');
            //dummy order, should be replaced with Niko products from the cart eventually
            const { error: orderError } = await createNikoOrder({
                products: [
                    {
                        productId: 'A89JM47PS23LN56KG42C',
                        count: 1, // can we use 0 here for a placeholder?
                        notes: 'Creating Order to check insurance eligibility',
                    },
                ],
            });

            if (orderError) {
                return;
            }
        }
    };

    const checkIfElibigilityCheckRequired = (ins) => {
        // this is bad code that I wrote, but it's a quick fix for now
        if (ins.insuranceStatus === 'Active') {
            dispatch(toggleInsurance(true));
            setCheckoutStep('is-eligible');
            return false;
        } else if (ins.payer.payerType === 'OTHER') {
            dispatch(toggleInsurance(false));
            setCheckoutStep('not-eligible');
            return false;
        } else if (!ins.payer.supportsEligibilityCheck) {
            dispatch(toggleInsurance(true));
            setCheckoutStep('insurance-card-question');
            return false;
        } else {
            return true;
        }
    };

    useEffect(() => {
        if (user && !ready) {
            getInsurance(user.id);
            setReady(true);
        }
    }, [user, ready, setReady, getInsurance]);

    const onPayerChange = useCallback(
        (consultRequiredByInsurance) => {
            dispatch(
                setConsultRequired(
                    (stateRequiredTelehealth && hstInCart) || consultRequiredByInsurance,
                ),
            );
        },
        [stateRequiredTelehealth, hstInCart, dispatch],
    );

    useEffect(() => {
        if (insurance) {
            const payer = insurance.payer;
            const consultRequiredByInsurance =
                (payer.consultRequiredForHST && hstInCart) ||
                (payer.consultRequiredForCPAP && cpapInCart);
            onPayerChange(consultRequiredByInsurance);
        }
    }, [insurance, cpapInCart, hstInCart, onPayerChange]);

    // This is the eligibility check heartbeat that checks for the returned value every 5 seconds
    useEffect(() => {
        let timer;
        let interval;
        if (checkoutStep === 'checking-eligibility') {
            //Try to check eligibility
            interval = setInterval(() => {
                checkInsuranceEligibility();
            }, 4000);
            timer = setTimeout(() => {
                // Ff it fails send them to not-found to check again,
                // and afterwards send them to insurance card question if it fails
                if (eligibilityAttempts < 1) {
                    setEligibilityAttempts((previous) => previous + 1);
                    setCheckoutStep('not-found');
                } else {
                    setCheckoutStep('insurance-card-question');
                    dispatch(toggleInsurance(true));
                }
            }, MAXIMUM_WAIT_TIME);
        }
        return () => {
            clearTimeout(timer);
            clearInterval(interval);
        };
        // eslint-disable-next-line
    }, [eligibilityAttempts, checkoutStep]);

    return (
        ready && (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <nav className="relative mb-4 flex w-full items-center justify-between">
                    {steps[checkoutStep].backButtonActive && (
                        <button
                            className="font-heading font-bold"
                            onClick={() => {
                                // send the user back to the beginning.
                                setCheckoutStep('check-coverage');
                            }}>
                            <LeftArrow className="text-black" />
                        </button>
                    )}
                </nav>
                <AnimatePresence>
                    {checkoutStep === 'checking-eligibility' && (
                        <Spinner
                            key="insuranceSpinner"
                            header="insurance info"
                            message="This process may take up to 2 minutes"
                        />
                    )}
                </AnimatePresence>
                <AnimatePresence mode="wait">
                    {checkoutStep === 'payment-choice' && <PaymentChoice key="paymentChoice" />}

                    {checkoutStep === 'check-coverage' && (
                        <CheckCoverage hasInsurance={!!insurance} />
                    )}

                    {checkoutStep === 'insurance-review' && (
                        <CurrentInsuranceInfo
                            checkIfElibigilityCheckRequired={checkIfElibigilityCheckRequired}
                        />
                    )}

                    {checkoutStep === 'insurance-details' && (
                        <AddInsuranceInfo
                            key="addInsurance"
                            addPatientInsurance={addPatientInsurance}
                        />
                    )}

                    {checkoutStep === 'insurance-card-question' && (
                        <UploadCardQuestion key="insuranceCardQuestion" />
                    )}

                    {checkoutStep === 'insurance-card' && (
                        <UploadInsuranceCard key="insuranceCard" />
                    )}

                    {checkoutStep === 'not-found' && (
                        <NotFound
                            key="notFound"
                            updatePatientCurrentInsurance={updatePatientCurrentInsurance}
                        />
                    )}

                    {checkoutStep === 'not-eligible' && <NotEligible key="notEligible" />}

                    {checkoutStep === 'is-eligible' && (
                        <Eligible
                            key="isEligible"
                            onInsuranceStepsComplete={onInsuranceStepsComplete}
                        />
                    )}
                </AnimatePresence>
            </motion.div>
        )
    );
};

export default InsuranceCheck;
