import { api } from '../auth';

const documentsAPI = api.injectEndpoints({
    endpoints: (builder) => ({
        changeDocumentStatus: builder.mutation({
            query: ({ fileId, patientId, status }) => ({
                url: `/files/${fileId}/status`,
                method: 'PUT',
                body: { status: status },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Files', id: arg?.patientId },
                { type: 'User', id: arg?.patientId },
            ],
        }),
        getPatientFiles: builder.query({
            query: (patientId) => ({
                url: `/files/patient/${patientId}`,
            }),
            providesTags: (result, error, arg) => {
                return [{ type: 'Files', id: arg }];
            },
        }),
        updateDocumentNotes: builder.mutation({
            query: ({ fileId, notes }) => ({
                url: `/files/${fileId}/notes`,
                method: 'PUT',
                body: { notes: notes },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Files', id: arg?.patientId },
                { type: 'User', id: arg?.patientId },
            ],
        }),
    }),
    overrideExisting: false,
});

export const {
    useChangeDocumentStatusMutation,
    useGetPatientFilesQuery,
    useUpdateDocumentNotesMutation,
} = documentsAPI;
