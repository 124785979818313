export function determineBlogPostsAndQuotes(determiningFactor, status) {
    switch (determiningFactor) {
        // case 'inTreatment': {
        //     //To-Do
        //     break;
        // }
        case 'cpapOrder':
            switch (status) {
                case 'NEW':
                case 'READY_FOR_FULFILLMENT':
                case 'ORDERED':
                    return {
                        quoteId: 'dr-mike-howell-2',
                        quoteLink: 'getting-started-with-cpap',
                        blogpostIds: [
                            'how-cpap-can-change-your-nights-and-days',
                            'settling-in-with-a-new-cpap',
                        ],
                    };
                case 'READY_TO_SHIP':
                    return {
                        quoteId: 'dr-mike-howell-2',
                        quoteLink: 'getting-started-with-cpap',
                        blogpostIds: ['gem-sleeps-cpap-tips-tricks', 'caring-for-your-cpap'],
                    };
                case 'SHIPPED':
                    return {
                        quoteId: 'dr-mike-howell-2-withcoach',
                        quoteLink: 'gem-sleeps-cpap-tips-tricks',
                        quoteLinkText: 'CPAP SET-UP GUIDE + Schedule Time with Coach',
                        blogpostIds: ['getting-started-with-cpap', 'caring-for-your-cpap'],
                    };

                case 'DELIVERED':
                    return {
                        quoteId: 'dr-mike-howell-2',
                        quoteLink: 'getting-started-with-cpap',
                        quoteLinkText: 'CPAP SET-UP GUIDE + Schedule Time with Coach',
                        blogpostIds: ['settling-in-with-a-new-cpap', 'gem-sleeps-cpap-tips-tricks'],
                    };

                case 'BACKORDERED':
                    return {
                        quoteId: 'dr-mike-howell-2',
                        quoteLink: 'getting-started-with-cpap',
                        blogpostIds: ['gem-sleeps-cpap-tips-tricks', 'caring-for-your-cpap'],
                    };

                default:
                    return {
                        quoteId: 'dr-mike-howell-2',
                        quoteLink: 'resmed-airsense11-quick-start-guide',
                        blogpostIds: ['what-is-a-cpap', 'getting-started-with-cpap'],
                    };
            }
        case 'hstOrder':
            switch (status) {
                case 'NEW':
                case 'READY_FOR_FULFILLMENT':
                case 'ORDERED':
                case 'READY_TO_SHIP':
                    return {
                        quoteId: 'just-one-night-of-sleep',
                        quoteLink: 'what-is-a-sleep-study-and-how-does-it-work',
                        blogpostIds: ['6-signs-you-may-have-sleep-apnea', 'what-is-sleep-apnea'],
                    };

                case 'SHIPPED':
                    return {
                        quoteId: 'follow-these-simple-steps',
                        quoteLink: 'https://www.mygemsleep.com/hst-how-to-guide',
                        blogpostIds: [
                            'untreated-sleep-apnea-impacting-sleep-quality',
                            'how-sleep-apnea-impacts-the-body',
                        ],
                    };
                case 'DELIVERED':
                case 'BACKORDERED':
                    return {
                        quoteId: 'follow-these-simple-steps',
                        quoteLink: 'https://www.mygemsleep.com/hst-how-to-guide',
                        blogpostIds: [
                            'home-sleep-test-set-up-video',
                            'how-many-hours-of-sleep-do-we-adults-need-it-is-a-trick-question',
                        ],
                    };
                default:
                    return {
                        quoteId: 'follow-these-simple-steps',
                        quoteLink: 'dr-howell',
                        blogpostIds: [
                            'how-much-better-you-could-feel',
                            'how-gem-addresses-barrier-to-osa-treatment',
                        ],
                    };
            }
        case 'beingDiagnosed':
            switch (status) {
                case 'ITAMAR_HST_NEEDS_REVIEW':
                    return {
                        quoteId: 'dr-howell',
                        quoteLink: 'understanding-your-ahi-score',
                        blogpostIds: [
                            '8-myths-about-sleep-studies-and-cpap-machines',
                            'how-many-hours-of-sleep-do-we-adults-need-it-is-a-trick-question',
                        ],
                    };
                case 'HST_COMPLETE':
                    return {
                        quoteId: 'dr-howell',
                        quoteLink: 'understanding-your-ahi-score',
                        blogpostIds: [
                            'finding-your-solution-to-sleep-apnea-with-gem',
                            'sleep-apnea-patient-stories',
                        ],
                    };
                case 'UPLOADED_SLEEP_STUDY_PENDING_CLINICAL_REVIEW':
                    return {
                        quoteId: 'sleep-apnea-is-common',
                        blogpostIds: [
                            'how-many-hours-of-sleep-do-we-adults-need-it-is-a-trick-question',
                            'sleep-apnea-and-heart-health',
                        ],
                    };
                case 'UPLOADED_SLEEP_STUDY_INCOMPLETE':
                    return {
                        quoteId: 'just-one-night-of-sleep',
                        blogpostIds: [
                            'what-is-a-sleep-study-and-how-does-it-work',
                            'untreated-sleep-apnea-impacting-sleep-quality',
                        ],
                    };
                case 'UPLOADED_SLEEP_STUDY_APPROVED':
                    return {
                        quoteId: 'dr-mike-howell',
                        blogpostIds: [
                            'what-is-a-cpap',
                            'finding-your-solution-to-sleep-apnea-with-gem',
                        ],
                    };
                //These statuses are not included in this case
                case 'NOT_INITIATED':
                case 'PENDING_CLINICAL_REVIEW':
                case 'HST_LAB_ORDER_COMPLETE':
                case 'ITAMAR_HST_REVIEW_COMPLETE':
                case 'CLINICIAN_TICKET_CREATED':
                case 'DOES_NOT_QUALIFY_FOR_HST':
                default:
                    return {
                        quoteId: 'dr-howell',
                        quoteLink: 'dr-howell',
                        blogpostIds: [
                            'how-much-better-you-could-feel',
                            'how-gem-addresses-barrier-to-osa-treatment',
                        ],
                    };
            }
        case 'suppliesOrder':
            switch (status) {
                case 'NEW':
                case 'READY_FOR_FULFILLMENT':
                    return {
                        quoteId: 'dr-mike-howell-3',
                        quoteLink: 'caring-for-your-cpap',
                        blogpostIds: [
                            '10-cpap-hacks-for-better-sleep',
                            'how-sleep-apnea-impacts-the-body',
                        ],
                    };
                case 'ORDERED':
                    return {
                        quoteId: 'dr-mike-howell-3',
                        quoteLink: 'caring-for-your-cpap',
                        blogpostIds: [
                            '10-cpap-hacks-for-better-sleep',
                            'traveling-with-your-cpap-machine',
                        ],
                    };
                case 'READY_TO_SHIP':
                    return {
                        quoteId: 'dr-mike-howell-3',
                        quoteLink: 'caring-for-your-cpap',
                        blogpostIds: ['what-is-rem-sleep', '10-cpap-hacks-for-better-sleep'],
                    };
                case 'SHIPPED':
                    return {
                        quoteId: 'dr-mike-howell-3',
                        quoteLink: 'caring-for-your-cpap',
                        blogpostIds: ['what-is-rem-sleep', 'traveling-with-your-cpap-machine'],
                    };

                case 'DELIVERED':
                case 'BACKORDERED':
                    return {
                        quoteId: 'dr-mike-howell-3',
                        quoteLink: 'caring-for-your-cpap',
                        blogpostIds: [
                            'how-sleep-apnea-impacts-the-body',
                            '10-cpap-hacks-for-better-sleep',
                        ],
                    };

                default:
                    return {
                        quoteId: 'dr-mike-howell-3',
                        quoteLink: 'caring-for-your-cpap',
                        blogpostIds: ['what-is-a-cpap', 'getting-started-with-cpap'],
                    };
            }
        case 'dmeReferral':
            switch (status) {
                case 'REQUESTED':
                case 'REFERRED':
                    return {
                        quoteId: 'sleep-apnea-is-common',
                        quoteLink: 'what-is-sleep-apnea',
                        blogpostIds: ['how-much-better-you-could-feel', 'what-is-a-cpap'],
                    };
                case 'CANCELLED':
                    return {
                        quoteId: 'dr-howell',
                        quoteLink: 'dr-howell',
                        blogpostIds: ['coaching-overview', 'gem-cpap-pricing'],
                    };
                default:
                    break;
            }
            break;
        case 'coachingStatus':
            switch (status) {
                case 'COACHING_REQUESTED':
                    return {
                        quoteId: 'dr-mike-howell',
                        quoteLink: 'how-sleep-apnea-impacts-the-body',
                        blogpostIds: ['coaching-overview', 'what-causes-snoring'],
                    };
                case 'SUPPORT_REVIEW_NEEDED':
                    return {
                        quoteId: 'dr-mike-howell',
                        quoteLink: 'how-sleep-apnea-impacts-the-body',
                        blogpostIds: ['coaching-overview', 'settling-in-with-a-new-cpap'],
                    };
                case 'CLINICAL_REVIEW_NEEDED':
                    return {
                        quoteId: 'dr-mike-howell',
                        quoteLink: 'how-sleep-apnea-impacts-the-body',
                        blogpostIds: ['coaching-overview', '10-cpap-hacks-for-better-sleep'],
                    };
                case 'APPROVED':
                    return {
                        quoteId: 'dr-mike-howell-2',
                        quoteLink: 'getting-started-with-cpap',
                        blogpostIds: ['coaching-overview', '10-cpap-hacks-for-better-sleep'],
                    };
                case 'NOT_APPROVED':
                    return {
                        quoteId: 'quote-on-diagnosis',
                        quoteLink: null,
                        blogpostIds: [
                            'oral-appliance-therapy-for-sleep-apnea-a-comfortable-customized-solution',
                            'what-causes-snoring',
                        ],
                    };
                default:
                    break;
            }
            break;
        case 'dentalOfficeHST':
            return {
                quoteId: 'follow-these-simple-steps',
                quoteLink: 'https://www.mygemsleep.com/hst-how-to-guide',
                blogpostIds: [
                    'untreated-sleep-apnea-impacting-sleep-quality',
                    'what-is-a-sleep-study-and-how-does-it-work',
                ],
            };
        case 'studyUploadPath':
            return {
                quoteId: 'dr-howell',
                blogpostIds: ['what-is-gem-sleep'],
            };
        case 'patientPath':
            return {
                quoteId: 'dr-howell',
                quoteLink: 'what-is-a-sleep-study-and-how-does-it-work',
                blogpostIds: ['what-is-gem-sleep'],
            };
        case 'cpapInCart':
            return {
                quoteId: 'sleep-apnea-is-common',
                quoteLink: 'what-is-sleep-apnea',
                blogpostIds: ['how-much-better-you-could-feel', 'what-is-a-cpap'],
            };
        default:
            break;
    }
}
