import { toast } from 'react-toastify';
import {
    useCreateOrderMutation,
    useGetPatientOrderHistoryQuery,
} from '../../../app/services/admin/orders';
import { AdminOrderList } from '../Orders';
import { OrderInformation, PatientCart, SquareOrders } from './TabComponents';
import { useNavigate, useParams } from 'react-router-dom';

const PatientOrderHistory = () => {
    const { orderId, patientId, subTab } = useParams();
    const navigate = useNavigate();

    //Queries and Mutations
    const { data: orderHistory, isLoading, error } = useGetPatientOrderHistoryQuery(patientId);
    const [createOrder] = useCreateOrderMutation();

    if (error)
        return (
            <p className="text-sm font-bold text-red">
                {error.data?.statusCode}: {error.data?.message}
            </p>
        );

    if (isLoading) return <p key={`orderhistory-loading-${patientId}`}>Loading...</p>;
    return (
        <div key={`orderhistory-${patientId}`}>
            <nav className="mb-4">
                <button
                    onClick={(ev) => {
                        navigate(`../patients/${patientId}/orderHistory/gem`, {
                            replace: true,
                        });
                    }}
                    className={`border-gray border-b py-2 min-w-[140px] ${subTab === 'gem' ? 'text-gem-green border-gem-green! font-bold' : 'text-gray hover:text-gem-green hover:font-bold'}`}>
                    GEM Orders
                </button>
                <button
                    onClick={(ev) => {
                        navigate(`../patients/${patientId}/orderHistory/square`, {
                            replace: true,
                        });
                    }}
                    className={`border-gray border-b py-2 min-w-[140px] ${subTab === 'square' ? 'text-gem-green border-gem-green! font-bold' : 'text-gray hover:text-gem-green hover:font-bold'}`}>
                    Square Orders
                </button>
                <button
                    onClick={(ev) => {
                        navigate(`../patients/${patientId}/orderHistory/cart`, {
                            replace: true,
                        });
                    }}
                    className={`border-gray border-b py-2 min-w-[140px] ${subTab === 'cart' ? 'text-gem-green border-gem-green! font-bold' : 'text-gray hover:text-gem-green hover:font-bold'}`}>
                    Cart
                </button>
                {subTab === 'order' && (
                    <button className="hover:cursor-default border-gray border-b py-2 min-w-[140px] text-gem-green border-gem-green! font-bold">
                        {'Order ' + orderId}
                    </button>
                )}
            </nav>
            {subTab === 'gem' && (
                <>
                    <button
                        key={`create-order-button-${patientId}`}
                        className="btn-primary-small w-1/3 ml-[1%] mb-4"
                        onClick={async () => {
                            const newOrder = await createOrder({ userId: patientId });

                            if (newOrder) {
                                navigate(
                                    `../patients/${patientId}/orderHistory/order/${newOrder.data.id}`,
                                );
                            } else {
                                toast.error('Something went wrong');
                            }
                        }}>
                        Create Order
                    </button>
                    <AdminOrderList orders={orderHistory} patientId={patientId} />
                </>
            )}
            {subTab === 'square' && <SquareOrders />}
            {subTab === 'cart' && <PatientCart />}
            {subTab === 'order' && <OrderInformation />}
        </div>
    );
};

export default PatientOrderHistory;
