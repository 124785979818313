import { format } from 'date-fns';
import { colorAppointmentStatus } from '../../../utils/constants';

function OrderAppointments({ order }) {
    return (
        <div key={`order-item-list-${order?.id}`} className="w-[98%] m-auto gap-y-2">
            {order?.appointments && order?.appointments?.length > 0 ? (
                <>
                    <div className="border-b border-gray grid grid-cols-[180px_100px_90px_120px_180px_140px] gap-x-2 p-2 ">
                        <p>Type</p>
                        <p>Date</p>
                        <p>Time</p>
                        <p>Status</p>
                        <p>Provider</p>
                        <p>Updated</p>
                    </div>

                    {order.appointments.map((appointment) => (
                        <div
                            key={`${appointment.id}`}
                            className={`grid grid-cols-[180px_100px_90px_120px_180px_140px] gap-x-2 }`}>
                            <p className="whitespace-nowrap overflow-hidden text-ellipsis p-2">
                                {appointment.appointmentType}
                            </p>
                            <p className="p-2">
                                {format(new Date(appointment.startAt), 'MM/dd/yyyy')}
                            </p>
                            <p className="p-2">{format(new Date(appointment.startAt), 'h:mm a')}</p>
                            <p className="p-2">
                                {colorAppointmentStatus(appointment.appointmentStatus)}
                            </p>
                            <div className="max-w-[18 0px] p-2">
                                <div
                                    className={`daisy-tooltip daisy-tooltip-right text-left flex items center max-h-[33.8px]`}
                                    data-tip={
                                        appointment.provider.firstName +
                                        ' ' +
                                        appointment.provider.lastName
                                    }>
                                    <p className=" max-w-[150px] whitespace-nowrap overflow-hidden text-ellipsis">
                                        {`${appointment.provider.firstName} ${appointment.provider.lastName}`}
                                    </p>
                                </div>
                            </div>

                            <p className="p-2">
                                {format(new Date(appointment.updatedAt), 'MM/dd/yyyy')}
                            </p>
                        </div>
                    ))}
                </>
            ) : (
                <p className="text-xl text-salmon py-4">There are no appointments in this order</p>
            )}
        </div>
    );
}

export default OrderAppointments;
