export const routes = [
    { path: '/dashboard', breadcrumb: 'Home' },
    { path: '/dashboard/diagnosis', breadcrumb: 'Diagnosis' },
    {
        path: '/dashboard/treatment',
        breadcrumb: 'Treatment',
        subroutes: [{ path: 'bundle', breadcrumb: 'Bundle' }],
    },
    { path: '/dashboard/care-team', breadcrumb: 'Care Team' },
    { path: '/dashboard/resources', breadcrumb: 'Resources' },
];
