import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../app/services/auth';
import {
    defaultOrderColumns,
    orderStatusesWithOptions,
    patientStatusesWithOptions,
} from '../../utils/constants';

const omitSingle = (key, { [key]: _, ...obj }) => obj;

const initialState = {
    careTeamOptions: null,
    payerOptions: null,
    partnersOptions: null,
    activePatientFilters: {
        //Patient Profile Values
        accountCreationStatus: null,
        cpapOrderStatus: null,
        dentalReferralStatus: null,
        homeSleepStudyStatus: null,
        initialCompliance: null,
        isDentalReferred: null,
        mdStatus: null,
        needsSleepStudyRetest: null,
        referFromOrganization: null,
        referredForCpap: null,
        referToSleepClinic: null,
        supplyOrdersStatus: null,
        //Other values
        careTeam: null,
        insurance: null,
        patientProfileSearch: null,
        resupply: null,
        searchTermType: null,
        searchTermValue: null,
        state: null,
        //General Options
        orderBy: { id: 'desc' },
        querySearch: null,
        skip: 0,
        take: 10,
    },
    availablePatientFilters: patientStatusesWithOptions,
    patientFilterComponents: [],
    activeOrderFilters: {
        appointmentStatus: null,
        appointmentType: null,
        careTeam: null,
        cpapOrderStatus: null,
        endDate: null,
        estimateStatus: null,
        fulfillmentStatus: null,
        homeSleepStudyStatus: null,
        mdStatus: null,
        initialCompliance: null,
        insurance: null,
        insuranceStatus: null,
        lastCompliance: null,
        orderByKey: 'ID',
        orderDate: null,
        orderItemType: null,
        orderStatus: null,
        orderType: null,
        priorAuthStatus: null,
        referFromOrganization: null,
        searchTermType: null,
        searchTermValue: null,
        startDate: null,
        supplyOrdersStatus: null,
        //General Options
        orderDesc: true,
        skip: 0,
        querySearch: null,
    },
    availableOrderFilters: orderStatusesWithOptions,
    orderFilterComponents: [],
    orderColumns: defaultOrderColumns,
    activeConsultFilters: {
        skip: 0,
        take: 15,
        appointmentStatus: null,
        appointmentType: null,
        providerId: null,
        firstName: '',
        lastName: '',
        startDate: null,
        endDate: null,
    },
    activeReferralFilters: {
        orderByKey: 'Created',
        orderDesc: true,
        dateSearch: null,
        startDate: null,
        endDate: null,
        referralStatus: null,
        organizationId: null,
    },
    activeResupplyFilters: {
        orderByKey: 'Created',
        orderDesc: true,
        page: 0,
    },
    practitionersToQuery: null,
};

export const adminSlice = createSlice({
    name: 'admin',
    initialState: initialState,
    reducers: {
        deleteStatusFilter: (state, { payload }) => {
            if (state[payload.type][payload.key]) {
                const newFilters = omitSingle(payload.value, state[payload.type][payload.key]);
                state[payload.type][payload.key] = newFilters;
            }
        },
        resetFilters: (state, { payload }) => {
            Object.keys(state[payload.filterType]).forEach(
                (obj) => (state[payload.filterType][obj] = initialState[payload.filterType][obj]),
            );
            state[payload.availableFilterType].forEach((obj) => (obj.available = true));
            state[payload.filterComponents] = [];
        },
        setAdminSliceValue: (state, { payload }) => {
            state[payload.key] = payload.value;
        },
        setStatusFilter: (state, { payload }) => {
            if (Array.isArray(payload)) {
                payload.forEach((item) => {
                    state[item.type][item.key] = item.value;
                });
            } else {
                state[payload.type][payload.key] = payload.value;
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(api.endpoints.logout.matchFulfilled, (state) => {
                return initialState;
            })
            .addMatcher(api.endpoints.getCoaches.matchFulfilled, (state, { payload }) => {
                state.careTeamOptions = payload.results.map((member) => ({
                    value: member.id,
                    label: member.firstName + ' ' + member.lastName,
                }));
            })
            .addMatcher(api.endpoints.getPayers.matchFulfilled, (state, { payload }) => {
                state.payerOptions = payload.map((payer) => ({
                    value: payer.id,
                    label: payer.name,
                }));
            })
            .addMatcher(
                api.endpoints.getPartnerOrganizations.matchFulfilled,
                (state, { payload }) => {
                    state.partnersOptions = payload.results.map((partner) => ({
                        value: { id: partner.id },
                        label: partner.name,
                    }));
                },
            )
            .addMatcher(
                api.endpoints.getAvailablePractitionersAdmin.matchFulfilled,
                (state, { payload }) => {
                    state.practitionersToQuery = payload;
                },
            );
    },
});

export const { deleteStatusFilter, resetFilters, setAdminSliceValue, setStatusFilter } =
    adminSlice.actions;

export default adminSlice.reducer;

export const selectValue = (state, value) => state[value];
