import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
//Images
import afterpayLogo from '../../images/AfterPayLogo.png';

import { useAddToCartMutation } from '../../app/services/cart';
import { toggle, toggleModal } from '../../features/ui/uiSlice';
import { formatSquarePrice, swapElements } from '../../utils/constants';
//Components
import { Modal, ProductOptions } from '../../components';
import { useInsuranceContext } from '../../components/Insurance';
import { StarterKitAccordion, StarterKitSlider } from '../../components/Treatment';
import { ReactComponent as InsuranceIcon } from '../../images/icons/insurance.svg';
import posthog from 'posthog-js';

const CPAPBrands = [
    {
        name: 'RESMED',
        skuMatcher: /GEM-RMD-/,
    },
    {
        name: 'RESVENT',
        skuMatcher: /GEM-RSV-/,
    },
    {
        name: 'REACT',
        skuMatcher: /GEM-REA-/,
    },
    {
        name: 'REACT',
        skuMatcher: /GEM-RCT-/,
    },
];

const getBrandName = (sku) => {
    for (let i = 0; i < CPAPBrands.length; i++) {
        if (CPAPBrands[i].skuMatcher.test(sku)) {
            return CPAPBrands[i];
        }
    }
};

const BundleDetails = () => {
    const dispatch = useDispatch();
    const { bundleId } = useParams();
    const { user } = useSelector((state) => state.auth);
    const { orderItems, usingInsurance } = useSelector((state) => state.cart);
    const { catalogItems, selectedProduct, selectedVariation } = useSelector(
        (state) => state.catalog,
    );
    const { modalsOpen } = useSelector((state) => state.ui);

    const [slides, setSlides] = useState(['']);
    const [priceComponents, setPriceComponents] = useState(null);
    const [addToCart] = useAddToCartMutation();
    const { insurance } = useInsuranceContext();

    // get the bundle from the square catalog by ID.
    const bundle = catalogItems.find((item) => {
        return item.itemData?.variations?.find((v) => v.id === bundleId);
    });

    //recommended variation based on the bundle selected
    const bundleVariation = bundle?.itemData?.variations?.find((v) => v.id === bundleId);

    // use this to filter bundles by brand.
    const cpapBrand = getBrandName(bundleVariation?.itemVariationData?.sku);

    const getPriceComponents = (price) => {
        // the prices for bundles in Square, reflect the discounted price,
        // so we need to calculate the original price in order to show the savings.
        const globalGemDiscount = 0.05;
        const originalPrice = price / (1 - globalGemDiscount);

        return {
            originalPrice: formatSquarePrice(originalPrice),
            discountedPrice: formatSquarePrice(price),
            savings: formatSquarePrice(originalPrice - price),
            gemDiscount: globalGemDiscount * 100,
        };
    };

    const bundleInCart = orderItems.some((p) => p.variantId === selectedVariation?.id);

    useEffect(() => {
        //Set slides to those in the selected product if it exists, defaulting to the bundle images
        const slides = [...(selectedProduct?.images ?? bundle?.images)];
        //Swap the first and second elements in the slides to move the mask to the first slide
        swapElements(slides, 0, 1);

        setSlides(slides);

        if (selectedProduct) {
            setPriceComponents(
                getPriceComponents(
                    selectedProduct?.itemData.variations[0].itemVariationData.priceMoney.amount,
                ),
            );
        }
    }, [selectedProduct, bundle?.images]);

    const itemsToOrder = [];
    if (selectedProduct && selectedVariation) {
        itemsToOrder.push({
            quantity: 1,
            sku: selectedVariation.itemVariationData.sku,
            single: true,
        });
    }

    //Add GEM Coaching to cash pay orders and insurance orders which cover it
    if (!usingInsurance || (usingInsurance && insurance?.payer?.CoachingCovered))
        itemsToOrder.push({
            quantity: 1,
            sku: 'GEMCOACH',
            single: true,
        });

    return (
        <div>
            <div className="mb-12 lg:mb-20 lg:flex lg:items-start">
                <div className="mb-10 lg:sticky lg:top-6 lg:mb-0 lg:w-1/2 lg:pr-24">
                    <StarterKitSlider slides={slides} />
                </div>
                <div className="lg:w-1/2">
                    <h3 className="font-heading text-5xl lg:text-6xl">
                        <span className="ph-no-capture">{user?.firstName}</span>'s CUSTOMIZED CPAP
                        STARTER KIT
                    </h3>
                    <p className="mb-3 text-xs lg:text-sm">by GEM SLEEP</p>
                    <div className="mb-5 pb-4">
                        <p className="mb-6 text-sm lg:mb-7 lg:text-lg">
                            Based on your virtual mask fitting and your clinician's treatment plan
                            we recommend starting with the CPAP starter bundle below! The bundle
                            includes the CPAP machine, hose, filters, mask system and 90 days of
                            supplies.
                        </p>
                        <p className="mb-4 font-bold">
                            The items in this bundle cannot be purchased individually.
                        </p>
                        {!usingInsurance && (
                            <p className="text-sm italicize">
                                <span className="mr-1">
                                    Pay for your CPAP machine over 4-interest free payments using
                                </span>
                                <img
                                    className="inline w-[85px]"
                                    src={afterpayLogo}
                                    alt=" Afterpay."
                                />
                            </p>
                        )}
                    </div>

                    <div className="mb-8 flex flex-col lg:flex-row lg:items-center lg:space-x-8">
                        <div>
                            {priceComponents ? (
                                <>
                                    <h3 className="lg:text-right font-heading text-3xl font-bold">
                                        <span>
                                            {usingInsurance ? (
                                                <p className="inline-flex items-center justify-center bg-purple-lightest p-4 text-xl font-heading mb-2 w-full lg:mb-0 lg:min-h-[68px] lg:w-[200px] ">
                                                    <span className="text-sm tracking-widest">
                                                        Submitted to Insurance
                                                    </span>
                                                    <InsuranceIcon className="ml-2 w-4" />
                                                </p>
                                            ) : (
                                                `${priceComponents.discountedPrice}`
                                            )}
                                        </span>
                                        {!usingInsurance && (
                                            <span className="font-heading text-2xl font-bold  text-gray-six">{` (${priceComponents.gemDiscount}% savings)`}</span>
                                        )}
                                    </h3>
                                    {!usingInsurance && (
                                        <div className="lg:text-right">
                                            <span className="font-heading text-lg font-bold text-gray-six line-through">
                                                ${priceComponents.originalPrice}
                                            </span>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <span>{`$---.--`}</span>
                            )}
                        </div>
                        <button
                            className="btn-primary lg:w-[200px] "
                            disabled={!selectedVariation}
                            onClick={() => {
                                if (!bundleInCart) {
                                    addToCart(itemsToOrder);

                                    posthog?.capture('Added CPAP bundle to cart', {
                                        sku: selectedVariation.itemVariationData.sku,
                                    });
                                }
                                dispatch(toggle({ key: 'cartOpen', override: true }));
                            }}>
                            {bundleInCart ? 'Product in cart' : 'Add to cart'}
                        </button>
                    </div>

                    {!usingInsurance && (
                        <div className="flex flex-col flex-wrap lg:flex-row gap-3 mb-3 tracking-wider text-sm lg:text-base font-heading leading-relaxed text-black">
                            <div>
                                <span className="rounded-xl bg-purple-lightest py-1 px-4 ">
                                    Payment plans available
                                </span>
                            </div>
                            <div>
                                <span className="rounded-xl bg-gem-blue py-1 px-4 ">
                                    Free Shipping
                                </span>
                            </div>
                            <div>
                                <span className="rounded-xl bg-gem-green py-1 px-4 ">
                                    Use FSA/HSA dollars
                                </span>
                            </div>
                            <div>
                                <span className="rounded-xl bg-purple-fair py-1 px-4 ">
                                    Some insurance accepted
                                </span>
                            </div>
                        </div>
                    )}
                    <p className="mb-6 text-sm lg:mb-7 lg:text-lg">
                        After order is placed we will review coverage with your insurance and send
                        an estimate, once estimate is approved the CPAP will ship.
                    </p>
                    <ProductOptions
                        selectedBundle={bundle}
                        recommendedBundleVariation={bundleVariation}
                        brand={cpapBrand}
                    />

                    <button
                        className="border-b border-gem-green text-xs font-bold lg:text-sm"
                        type="button"
                        onClick={() => {
                            dispatch(toggleModal('productRecommendations'));
                        }}>
                        How we make our recommendations
                    </button>
                    <br />
                </div>
            </div>

            <StarterKitAccordion bundle={bundle} />

            <Modal
                showClose={true}
                isOpen={modalsOpen.indexOf('productRecommendations') > -1}
                closeHandler={() => {
                    dispatch(toggleModal('productRecommendations'));
                }}>
                <div className="space-y-4 text-gray-darker">
                    <h3 className="mb-4 font-heading text-4xl lg:text-7xl">
                        GEM SLEEP RECOMMENDAtions
                    </h3>
                    <p className="text-[13px] lg:text-base">
                        GEM SLEEP uses a third party proprietary algorithm combined with GEM SLEEP
                        provider expertise to help determine the best mask style and size for you.
                        95% of patients who order the recommended size are happy with the mask
                        features and fit.
                    </p>
                    <p className="text-[13px] lg:text-base">
                        See our FAQ document for information on our Mask Satisfaction Guarantee and
                        product warranty.
                    </p>
                </div>
            </Modal>
        </div>
    );
};

export default BundleDetails;
