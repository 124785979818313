import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { useGetBlogPostsQuery } from '../../app/services/webflow';

//Components
import { useInsuranceContext } from '../../components/Insurance';
import { PageHeader } from '../../components/LayoutComponents';
import {
    CareTeamCTA,
    Coaching,
    CPAPOrdered,
    CPAPTherapyReorder,
    DentalReferral,
    DMETherapy,
    InPersonClinicNeeded,
    OralTherapyTreatment,
    RetestNeeded,
    RecommendedCPAPs,
    ResupplyStatus,
    StartMaskFitting,
    SupplyStoreCallout,
    ComplianceCallout,
} from '../../components/Treatment';
import { AppointmentReminder } from '../../components/Appointments';
import { ArticlesSlider } from '../../components';

const Home = () => {
    //Redux state values
    const { user } = useSelector((state) => state.auth);
    const { recommendedCPAPBundles } = useSelector((state) => state.catalog);
    const { cpapOrder } = useSelector((state) => state.orders);
    const { dmeReferral, enrolledInResupply, userNeedsComplianceVisit } = useSelector(
        (state) => state.ui,
    );

    //Context
    const { insurance } = useInsuranceContext();

    //Queries
    const { data: blogPosts } = useGetBlogPostsQuery();

    //Used to flag that the cpap is ordered
    const cpapOrderedButNotDelivered = cpapOrder && cpapOrder?.fulfillmentStatus !== 'DELIVERED';

    //User has SovaSage recommendations
    const userHasRecommendations = recommendedCPAPBundles?.length > 0;

    //Patient chose to be referred to DME and hasn't changed insurance
    const patientReferredToDME = dmeReferral && userHasRecommendations;

    //Patient in treatment with DME
    const inDMETreatment =
        user?.patientProfile?.treatments?.find((treatment) => treatment.isActive) &&
        patientReferredToDME;

    //User is in dental treatment or dental treatment support
    const inDentalTreatment = user?.patientProfile?.dentalReferralStatus === 'IN_TREATMENT';

    //User is in treatment with GEM
    const inTreatment = cpapOrder?.fulfillmentStatus === 'DELIVERED';

    //User not yet in treatment
    const userNotInTreatment = !inTreatment && !inDentalTreatment && !inDMETreatment;

    //User in coaching program
    const userHasCoachingResult =
        !inTreatment && ['APPROVED', 'NOT_APPROVED'].includes(user?.patientProfile?.coachingStatus);

    //User applying to coaching program
    const userApplyingToCoachingProgram =
        !userHasCoachingResult && user?.patientProfile?.coachingStatus !== 'NOT_INITIATED';

    //User not yet referred for cpap, not in treatment, and is not applying to coaching
    const showPreRecCondition =
        !user?.patientProfile?.isDentalReferred &&
        !user?.patientProfile?.referredForCpap &&
        !user?.patientProfile?.needsSleepStudyRetest &&
        !user?.patientProfile?.referToSleepClinic &&
        !userApplyingToCoachingProgram &&
        !userHasCoachingResult;

    return user?.patientProfile?.homeSleepStudyStatus === 'HST_RECEIVED_IN_OFFICE' ? (
        <Navigate to="dentist-confirmation" />
    ) : showPreRecCondition ? (
        <Navigate to="treatment-options" />
    ) : (
        <>
            <PageHeader>
                {<span className="ph-no-capture">{user?.firstName}</span>}'s{' '}
                <span className="text-gem-purple">treatment</span>
            </PageHeader>
            {inDMETreatment ? null : inTreatment ? (
                <p className="mb-4 max-w-4xl text-sm lg:text-lg">
                    You can check back here anytime to get support with your current treatment,
                    order additional supplies or explore additional treatment options. We have your
                    back the whole time.
                </p>
            ) : inDentalTreatment ? (
                <p className="mb-4 max-w-4xl text-sm lg:text-lg">
                    You can check back here anytime to get support with your current treatment,
                    review your dentist's contact information or explore additional treatment
                    options. We have your back the whole time.
                </p>
            ) : patientReferredToDME ? (
                <p className="mb-4 max-w-4xl text-sm lg:text-lg">
                    Below are your treatment recommendations from GEM SLEEP. NOTE: We've submitted a
                    referral to {insurance?.payer.dmeReferralOrganization?.name},{' '}
                    {insurance?.payer.name}'s preferred CPAP supplier. If you wish to cancel your
                    order and want to pay on your own (including HSA/FSA) you may proceed forward
                    with ordering through GEM SLEEP. Click 'order now' to complete the checkout
                    process. .
                </p>
            ) : userApplyingToCoachingProgram ||
              userHasCoachingResult ||
              user?.patientProfile?.needsSleepStudyRetest ||
              user?.patientProfile?.referToSleepClinic ? null : (
                <p className="mb-4 max-w-4xl text-sm lg:text-lg">
                    GEM SLEEP has created a personalized treatment recommendation for you. Read more
                    about each option below and decide which will work best to get you back on track
                    to restful sleep. GEM SLEEP will have your back the whole time. From supplies to
                    support, we are here for you!
                </p>
            )}
            <AppointmentReminder />

            {user?.patientProfile?.needsSleepStudyRetest && <RetestNeeded />}

            {user?.patientProfile?.referToSleepClinic && <InPersonClinicNeeded />}

            {inTreatment && (
                <>
                    {cpapOrder && <ResupplyStatus />}
                    <CPAPTherapyReorder />
                </>
            )}

            {inDentalTreatment && <OralTherapyTreatment />}

            {inDMETreatment && <DMETherapy />}

            {userNotInTreatment && (
                <>
                    {userHasCoachingResult && <Coaching />}

                    {userHasRecommendations && !cpapOrderedButNotDelivered && (
                        <RecommendedCPAPs
                            cpaps={recommendedCPAPBundles}
                            user={user}
                            patientReferredToDME={patientReferredToDME}
                        />
                    )}

                    {cpapOrderedButNotDelivered && user?.patientProfile?.referredForCpap && (
                        <CPAPOrdered />
                    )}

                    {!userHasRecommendations && user?.patientProfile?.referredForCpap && (
                        <StartMaskFitting />
                    )}

                    {user?.patientProfile?.isDentalReferred && <DentalReferral />}
                </>
            )}

            {userNeedsComplianceVisit && <ComplianceCallout />}

            {(user?.patientProfile?.coachingStatus === 'APPROVED' || inTreatment) &&
                !enrolledInResupply && <SupplyStoreCallout />}

            {!user?.patientProfile?.referToSleepClinic && <CareTeamCTA />}
            {blogPosts && (
                <ArticlesSlider
                    className="mb-10"
                    label="Sleep Resources"
                    slidesPerViewDesktop={3}
                    slides={blogPosts}
                    //To be removed once file is typed
                    gap={20}
                    buttonType="controls"
                    viewAllLink={null}
                    slidesPerView={1.1}
                />
            )}
        </>
    );
};

export default Home;
