import topRightArrow from '../../images/top-right-arrow.svg';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const CareTeamCTA = () => {
    const { user } = useSelector((state) => state.auth);
    const { cpapOrder } = useSelector((state) => state.orders);
    const { isCoachingOnlyPath } = useSelector((state) => state.ui);

    if (user?.careTeam?.length > 0) {
        return (
            <div className="mb-7 lg:flex">
                <div className="relative flex-1 bg-black p-9 text-center lg:p-4 lg:text-left">
                    <span className="text-sm font-bold text-white lg:absolute lg:top-1/2 lg:left-4 lg:-translate-y-1/2 lg:text-lg">
                        {cpapOrder ? (
                            <>
                                Need Help?{' '}
                                <span className="text-purple-lightest font-bold">GEM Coaching</span>{' '}
                                is here for you.
                            </>
                        ) : isCoachingOnlyPath ? (
                            'GEM COACHING makes the transition to using CPAP easier.'
                        ) : (
                            <>
                                <span className="text-purple-lightest font-bold">GEM Coaching</span>{' '}
                                is available with all treatment options.
                            </>
                        )}
                    </span>
                </div>
                <Link
                    to="/dashboard/care-team"
                    className="bg-gem-purple hover:bg-purple-medium text-white font-bold inline-flex items-center w-full lg:w-[250px] py-10 justify-center"
                    style={{ minWidth: '250px', height: '100%' }}>
                    Meet your Care Team
                    <img src={topRightArrow} alt="" className="ml-3 scale-150" />
                </Link>
            </div>
        );
    } else {
        return null;
    }
};

export default CareTeamCTA;
