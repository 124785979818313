import { useState } from 'react';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import { FaCheck } from 'react-icons/fa';

import { enrollmentStatuses, formatDate } from '../../../../utils/constants';
import { AdminOrderList } from '../../Orders';
import { useUpdateResupplyMutation } from '../../../../app/services/admin/orders';

function PatientResupplyListItem({ resupply }) {
    //Redux State Values

    //Local State Values
    const [enrollmentStatus, setEnrollmentStatus] = useState(resupply.enrollmentStatus);
    const [createdAtDate, setCreatedAtDate] = useState(resupply.createdAt);
    const [nextShipmentDate, setNextShipmentDate] = useState(resupply.nextShipmentDate);
    const [enrolledWithNiko, setEnrolledWithNiko] = useState(resupply.enrolledWithNiko);

    //Queries
    const [updateResupply] = useUpdateResupplyMutation();

    async function handleResupplyUpdateClick() {
        const update = updateResupply({
            id: resupply.id,
            userId: resupply?.userId,
            createdAt: createdAtDate,
            enrollmentStatus: enrollmentStatus,
            nextShipmentDate: nextShipmentDate,
            enrolledWithNiko: enrolledWithNiko,
        });
        if (update) {
            toast.success('Resupply Updated');
        }
    }

    return (
        <div className="relative rounded-xl bg-gray-darker p-5 mb-4 space-y-4">
            <button
                className="sm:absolute right-3 btn-primary-small min-w-[120px]"
                disabled={
                    enrolledWithNiko === resupply.enrolledWithNiko &&
                    enrollmentStatus === resupply.enrollmentStatus &&
                    formatDate(nextShipmentDate) === formatDate(resupply.nextShipmentDate) &&
                    formatDate(createdAtDate) === formatDate(resupply.createdAt)
                }
                onClick={() => handleResupplyUpdateClick()}>
                Update
            </button>
            <div className="flex gap-x-4 gap-y-2 flex-col xl:flex-row xl:items-center xl:gap-y-0">
                <p className="min-w-[150px]">Enrollment Date:</p>
                <DatePicker
                    isClearable
                    className="appearance-none border border-gray rounded bg-transparent p-2 text-sm text-white"
                    selected={createdAtDate ? new Date(createdAtDate) : null}
                    onChange={(date) => {
                        setCreatedAtDate(date);
                    }}
                />

                <p className="min-w-[175px]">Next Shipment Date:</p>
                <DatePicker
                    isClearable
                    className="appearance-none border border-gray rounded bg-transparent p-2 text-sm text-white"
                    selected={nextShipmentDate ? new Date(nextShipmentDate) : null}
                    onChange={(date) => {
                        setNextShipmentDate(date);
                    }}
                />
            </div>
            <div className="flex gap-x-4 gap-y-2 flex-col xl:flex-row xl:items-center xl:gap-y-0">
                <p className="min-w-[150px]">Enrollment Status:</p>
                <select
                    className="w-full rounded-md text-black h-[37px] max-w-[250px]"
                    multiple={false}
                    defaultValue={resupply.enrollmentStatus}
                    value={enrollmentStatus}
                    name="enrollmentStatus"
                    onChange={(e) => setEnrollmentStatus(e.target.value)}>
                    {enrollmentStatuses.map((enrollmentStatus, index) => (
                        <option
                            className="text-white bg-[#2b2e3b]"
                            key={`orderStatus-${index}`}
                            value={enrollmentStatus}>
                            {enrollmentStatus}
                        </option>
                    ))}
                </select>
                <p className="min-w-[150px]">Enrolled in Niko:</p>
                <div className="relative">
                    <input
                        type="checkbox"
                        checked={enrolledWithNiko}
                        onChange={(e) => setEnrolledWithNiko(!enrolledWithNiko)}
                        className="appearance-none h-6 w-6 border-4 bg-transparent border-green-400 checked:bg-green-600 checked:border-green-600 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer"
                    />
                    <FaCheck className="absolute-center pointer-events-none w-2 scale-150 text-white opacity-0 transition" />
                </div>
            </div>
            <div className="flex gap-x-4 gap-y-2 flex-col xl:flex-row xl:items-center xl:gap-y-0"></div>
            <h2 className="font-heading text-xl lg:text-3xl">Resupply Orders</h2>

            <AdminOrderList orders={resupply.resupplyOrders ?? []} patientId={resupply.userId} />
        </div>
    );
}

export default PatientResupplyListItem;
