import { Fragment, useEffect, useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { toast } from 'react-toastify';
import {
    useGetDiagnosesQuery,
    useUpdatePatientDiagnosesMutation,
} from '../../../../app/services/admin/admin';
import { useUpdatePatientProfileMutation } from '../../../../app/services/auth';

function determineClinicalStatus(mdStatus, values) {
    const isDiagnosing =
        values?.needsSleepStudyRetest ||
        values?.referredForCpap ||
        values?.diagnoses?.length > 0 ||
        values?.isDentalReferred ||
        values?.referToSleepClinic;

    if (mdStatus === 'UPLOADED_SLEEP_STUDY_PENDING_CLINICAL_REVIEW' && isDiagnosing) {
        return 'UPLOADED_SLEEP_STUDY_APPROVED';
    } else if (isDiagnosing) {
        return 'HST_COMPLETE';
    }
}

function validateInput(values) {
    //Dental Referred and CPAP patients should not need a retest
    if ((values?.isDentalReferred || values?.referredForCpap) && values?.needsSleepStudyRetest) {
        return 'Patient cannot be recommended treatment if they need a retest';
        //Dental Referred and CPAP patients should not be referred to an in-person sleep clinic
    } else if (
        (values?.isDentalReferred || values?.referredForCpap) &&
        values?.referToSleepClinic
    ) {
        return 'Patient cannot be recommended treatment if they are referred to a sleep clinic';
        //MD should not reset mdStatus back to NOT_INITIATED
    } else if (values?.ahi === '' && values?.referredForCpap) {
        return 'Patient must have an AHI assigned';
    } else if (values?.diagnoses.length > 0 && values?.needsSleepStudyRetest) {
        return 'Patients needing retest cannot be diagnosed with sleep apnea';
    } else if (values?.diagnoses.length === 0 && values?.referredForCpap) {
        return 'Patients cannot be referred for CPAP without a diagnosis';
    } else if (
        values?.diagnoses.length > 0 &&
        !values?.referredForCpap &&
        !values?.isDentalReferred &&
        !values?.referToSleepClinic
    ) {
        return 'Patients needs a recommendation when being diagnosed';
    }
    return null;
}

function yesNoFormField({ title, fieldName, setValues, values }) {
    return (
        <>
            <p className="font-semibold">{title}</p>
            <div className="flex items-center space-x-2">
                <span>Yes</span>
                <div className="relative">
                    <input
                        type="radio"
                        name={fieldName}
                        checked={values[fieldName] !== null ? values[fieldName] : null}
                        onChange={() => {
                            const newValues = { ...values };
                            newValues[fieldName] = true;
                            setValues(newValues);
                        }}
                        className="appearance-none flex-shrink-0 h-6 w-6 border-4 bg-transparent border-green-400 checked:bg-green-600 checked:border-green-600 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer"
                    />
                    <FaCheck className="transition absolute-center w-2 opacity-0 scale-150 pointer-events-none text-white" />
                </div>
            </div>
            <div className="flex items-center space-x-2">
                <span>No</span>
                <div className="relative">
                    <input
                        type="radio"
                        name={fieldName}
                        checked={values[fieldName] !== null ? !values[fieldName] : null}
                        onChange={() => {
                            const newValues = { ...values };
                            newValues[fieldName] = false;
                            setValues(newValues);
                        }}
                        className="appearance-none flex-shrink-0 h-6 w-6 border-4 bg-transparent border-green-400 checked:bg-green-600 checked:border-green-600 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer"
                    />
                    <FaCheck className="transition absolute-center w-2 opacity-0 scale-150 pointer-events-none text-white" />
                </div>
            </div>
        </>
    );
}

const PatientDiagnosis = ({ patient }) => {
    // get all diagnosis
    const { data: diagnoses } = useGetDiagnosesQuery();
    const [updatePatientProfile] = useUpdatePatientProfileMutation();
    const [updatePatientDiagnoses] = useUpdatePatientDiagnosesMutation();

    const initialValues = {
        ahi: patient.patientProfile.ahi ? patient.patientProfile.ahi : '',
        isDentalReferred: patient.patientProfile.isDentalReferred,
        mdStatus: patient.patientProfile.mdStatus,
        needsSleepStudyRetest: patient.patientProfile.needsSleepStudyRetest,
        referredForCpap: patient.patientProfile.referredForCpap,
        referToSleepClinic: patient.patientProfile.referToSleepClinic,
        diagnoses: patient.diagnoses?.map((d) => d.diagnosis) || [],
    };

    //Store current set of values in local state
    const [values, setValues] = useState(initialValues);

    useEffect(() => {
        setValues(initialValues);
        // eslint-disable-next-line
    }, [patient]);

    //Track changed values
    const changedValues = {};

    Object.keys(values)
        .filter((key) => values[key] !== initialValues[key])
        .forEach((k) => {
            if (k !== 'diagnoses') {
                changedValues[k] = values[k];
            }
        });

    // compare array of diagnosis objects

    const diagnosesChanged =
        values.diagnoses.length !== initialValues.diagnoses.length ||
        values.diagnoses.some(
            (d) => !initialValues.diagnoses.some((id) => id.diagnosisId === d.diagnosisId),
        );
    if (diagnosesChanged === true) {
        changedValues['diagnoses'] = values.diagnoses;
    }
    const noChangesMade = Object.keys(changedValues).length === 0;

    const errorText = validateInput(values);

    const submitHandler = async (values) => {
        const mdStatus = determineClinicalStatus(patient.patientProfile.mdStatus, values);
        if (values.diagnoses) {
            // add new diagnoses
            const diagnosisResponse = await updatePatientDiagnoses({
                userId: patient.id,
                diagnoses: values.diagnoses,
            });

            if (!diagnosisResponse) {
                toast.error('Could not update patient diagnosis');
                return;
            }
        }

        const profileResponse = await updatePatientProfile({
            id: patient.patientProfile.id,
            userId: patient.id,
            patientFields: {
                ahi: Number(values.ahi),
                isDentalReferred: values.isDentalReferred,
                mdStatus: mdStatus,
                needsSleepStudyRetest: values.needsSleepStudyRetest,
                referredForCpap: values.referredForCpap,
                referToSleepClinic: values.referToSleepClinic,
            },
        });

        if (profileResponse) {
            toast.success('Patient Updated');
        } else {
            toast.error('Could not update patient diagnosis');
            return;
        }
    };

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                //Submit only changed values
                submitHandler(changedValues);
            }}>
            <div className="grid xxl:grid-cols-[1fr_750px]">
                <div className="grid grid-cols grid-cols-[60px_1fr] sm:grid-cols-[110px_1fr] gap-y-4 mb-4 items-center xxl:pr-8">
                    <p className="text-xl font-bold">AHI:</p>
                    <input
                        type="text"
                        name="ahi"
                        value={values.ahi}
                        onChange={(e) => setValues({ ...values, ahi: e.target.value })}
                        className="border border-gray bg-transparent p-2 text-right w-[60%] sm:max-w-[500px] "
                    />
                    {diagnoses?.map((diagnosis, idx) => (
                        <Fragment key={idx}>
                            <div className="flex items-center space-x-2">
                                <div className="relative">
                                    <input
                                        type="checkbox"
                                        name={diagnosis.id}
                                        checked={values.diagnoses.some(
                                            (d) => d.id === diagnosis.id,
                                        )}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setValues({
                                                    ...values,
                                                    diagnoses: [...values.diagnoses, diagnosis],
                                                });
                                            } else {
                                                //remove diagnosis from list
                                                setValues({
                                                    ...values,
                                                    diagnoses: values.diagnoses.filter(
                                                        (d) => d.id !== diagnosis.id,
                                                    ),
                                                });
                                            }
                                        }}
                                        className="appearance-none h-6 w-6 border-4 bg-transparent border-green-400 checked:bg-green-600 checked:border-green-600 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer"
                                    />
                                    <FaCheck className="transition absolute-center w-2 opacity-0 scale-150 pointer-events-none text-white" />
                                </div>
                            </div>
                            <p className="font-semibold">{diagnosis.icdDescription}</p>
                        </Fragment>
                    ))}
                </div>
                <div className="grid xxl:grid-cols-2">
                    <p className="font-heading tracking-widest col-span-2 text-3xl text-gray mb-4 mt-1">
                        Recommendations:
                    </p>
                    <div className="grid col-span-2 xxl:col-span-1 grid-cols-[110px_75px_55px] sm:grid-cols-[220px_75px_55px] xxl:grid-cols-[160px_75px_55px] items-center gap-y-4 mb-4 ">
                        {yesNoFormField({
                            title: 'CPAP treatment: ',
                            fieldName: 'referredForCpap',
                            setValues: setValues,
                            values: values,
                        })}
                        {yesNoFormField({
                            title: 'Dental referral: ',
                            fieldName: 'isDentalReferred',
                            setValues: setValues,
                            values: values,
                        })}
                    </div>
                    <div className="grid grid-cols-[110px_75px_55px] sm:grid-cols-[220px_75px_55px] items-center gap-y-4 mb-4 ">
                        {yesNoFormField({
                            title: 'Refer to sleep clinic: ',
                            fieldName: 'referToSleepClinic',
                            setValues: setValues,
                            values: values,
                        })}
                        {yesNoFormField({
                            title: 'Needs sleep study retest: ',
                            fieldName: 'needsSleepStudyRetest',
                            setValues: setValues,
                            values: values,
                        })}
                    </div>
                </div>
            </div>
            <div className="flex flex-col space-y-4 lg:space-y-0 lg:flex-row lg:space-x-4 items-center">
                <button
                    className="btn-primary w-full lg:btn-primary-small lg:w-[200px]"
                    type="submit"
                    disabled={noChangesMade || errorText !== null}>
                    Save
                </button>
                <button
                    className="btn-secondary w-full lg:btn-secondary-small lg:w-[200px]"
                    type="button"
                    onClick={() => setValues(initialValues)}
                    disabled={noChangesMade}>
                    Clear
                </button>
                <p className="text-red ">{!noChangesMade && errorText}</p>
            </div>
        </form>
    );
};

export default PatientDiagnosis;
