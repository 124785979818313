import { AppointmentSchedulingButton } from '../Appointments';
import { useSelector } from 'react-redux';

const DMETherapy = () => {
    const { user } = useSelector((state) => state.auth);
    const { dmeReferral } = useSelector((state) => state.ui);

    const inDMETreatment = user?.patientProfile?.treatments.find((treatment) => treatment.isActive);

    return (
        <div className="max-w-xl mb-4">
            <h4 className="mb-2 lg:mb-7 flex items-center font-heading text-3xl lg:text-5xl">
                <span className="mr-4">cpap therapy</span>
            </h4>
            <h5 className="mb-2 flex items-center font-heading text-xl lg:text-3xl">
                {inDMETreatment
                    ? `You're currently in CPAP treatment with ${dmeReferral?.organization.name}.`
                    : `You've been referred to ${dmeReferral?.organization.name}`}
            </h5>
            <p className="mb-2 lg:mb-4">
                GEM SLEEP will remain your sleep provider during treatment. If you need a follow-up
                visit with a GEM clinician, please find a time below.{' '}
            </p>
            <AppointmentSchedulingButton
                sku="COMPLIANCE"
                styling="btn-primary w-full lg:w-auto flex items-center space-x-2"
                label="Schedule an appointment"
            />
        </div>
    );
};

export default DMETherapy;
