import { useDispatch, useSelector } from 'react-redux';
import { useGetFeedbackQuery } from '../../app/services/auth';
import { toggleModal } from '../../features/ui/uiSlice';
import { ReactComponent as ThumbsDown } from '../../images/thumbs-down.svg';
import { ReactComponent as ThumbsUp } from '../../images/thumbs-up.svg';
import { determineBlogPostsAndQuotes } from './determineBlogPostsAndQuotes';
import { determineOrderCategories, formatAppointmentForDisplay } from '../constants';

const FeedbackThumbs = () => {
    const dispatch = useDispatch();

    return (
        <span className="flex items-center space-x-2 pt-2">
            <button
                className="relative h-9 w-9 rounded-full border bg-white transition hover:border-green-400"
                onClick={(ev) => {
                    dispatch(toggleModal('goodRating'));
                }}>
                <ThumbsUp className="absolute-center" />
            </button>
            <button
                className="relative h-9 w-9 rounded-full border bg-white transition hover:border-green-400"
                onClick={(ev) => {
                    dispatch(toggleModal('badRating'));
                }}>
                <ThumbsDown className="absolute-center" />
            </button>
        </span>
    );
};

export const useGetHomeStatus = () => {
    const dispatch = useDispatch();

    //redux state values
    const { user } = useSelector((state) => state.auth);
    const { cpapInCart } = useSelector((state) => state.cart);
    const { currentSupplies } = useSelector((state) => state.catalog);
    const { uploadedCharts } = useSelector((state) => state.files);
    const { cpapOrder, hstOrder, orderWithEstimateToApprove, suppliesOrder } = useSelector(
        (state) => state.orders,
    );
    const { currentAppointment, dmeReferral, showFeedbackBox } = useSelector((state) => state.ui);

    //queries
    const { data: feedback } = useGetFeedbackQuery();

    const beingDiagnosed = [
        'ITAMAR_HST_NEEDS_REVIEW',
        'HST_COMPLETE',
        'UPLOADED_SLEEP_STUDY_PENDING_CLINICAL_REVIEW',
        'UPLOADED_SLEEP_STUDY_INCOMPLETE', // Need to determine if this should be here
        'UPLOADED_SLEEP_STUDY_APPROVED',
    ].includes(user?.patientProfile?.mdStatus);

    const coachingProgram = user?.patientProfile?.coachingStatus !== 'NOT_INITIATED';
    const coachsName = user?.careTeam?.length > 0 ? user.careTeam[0].careTeamUser.firstName : null;

    const cpapBrand = cpapOrder?.orderItems
        .find((p) => p.itemCategory === 'CPAP_SUPPLIES')
        .name.split(' ')[0]
        .toLowerCase();

    const dentalOfficeHSTPatient =
        user?.patientProfile?.homeSleepStudyStatus === 'HST_RECEIVED_IN_OFFICE';

    // const treatmentInCompliance = user?.patientProfile?.treatments.some(
    //     (treatment) => treatment.isActive && treatment.initialCompliance === 'COMPLIANT',
    // );

    //Determine if user has a prior sleep study to upload or coming is in as coaching only
    const studyToUpload =
        user?.patientProfile?.stopBangResult?.hasPriorStudy &&
        user?.patientProfile?.stopBangResult?.previouslyDiagnosedWithSleepApnea &&
        !user?.patientProfile?.stopBangResult?.gained20Lbs &&
        user?.patientProfile?.stopBangResult?.howBroughtToGem !== 'NEED_NEW_SLEEP_TEST';

    const appointmentScheduledOrCancelled =
        currentAppointment?.appointmentStatus === 'SCHEDULED' ||
        (currentAppointment?.appointmentStatus === 'CANCELLED' &&
            currentAppointment?.appointmentType === 'Initial Consultation') ||
        currentAppointment?.appointmentStatus === 'NOSHOW';

    const userNeedsComplianceVisit =
        user?.patientProfile?.treatments.some(
            (treatment) => treatment.isActive && treatment.initialCompliance === 'COMPLIANT',
        ) &&
        !user?.appointments.some(
            (appointment) =>
                appointment.appointmentType === 'Compliance Visit' &&
                ['SCHEDULED', 'OCCURRED'].includes(appointment.appointmentStatus),
        );

    //Default to most senior order (supplies, cpap, hst) or referral status, check if in coaching program, look at if they are a dental HST, otherwise they are still being diagnosed
    const determiningFactor = suppliesOrder
        ? 'suppliesOrder'
        : // : inTreatment
          //   ? 'inTreatment'
          cpapOrder
          ? 'cpapOrder'
          : dmeReferral
            ? 'dmeReferral'
            : cpapInCart
              ? 'cpapInCart'
              : beingDiagnosed
                ? 'beingDiagnosed'
                : hstOrder
                  ? 'hstOrder'
                  : coachingProgram
                    ? 'coachingStatus'
                    : dentalOfficeHSTPatient
                      ? 'dentalOfficeHST'
                      : studyToUpload
                        ? 'studyUploadPath'
                        : 'patientPath';

    //Switch for determining status to check for blogposts and quotes
    let statusToCheck = null;
    switch (determiningFactor) {
        case 'suppliesOrder':
            statusToCheck = suppliesOrder?.fulfillmentStatus;
            break;
        // case 'inTreatment':
        //     statusToCheck = treatmentInCompliance;
        //     break;
        case 'cpapOrder':
            statusToCheck = cpapOrder?.fulfillmentStatus;
            break;
        case 'hstOrder':
            statusToCheck = hstOrder?.fulfillmentStatus;
            break;
        case 'dmeReferral':
            statusToCheck = dmeReferral?.referralStatus;
            break;
        case 'coachingStatus':
            statusToCheck = user?.patientProfile?.coachingStatus;
            break;
        case 'dentalOfficeHST':
            statusToCheck = user?.patientProfile?.homeSleepStudyStatus;
            break;
        case 'cpapInCart':
        case 'beingDiagnosed':
            statusToCheck = user?.patientProfile?.mdStatus;
            break;
        case 'studyUploadPath':
        case 'patientPath':
        default:
            statusToCheck = user?.patientProfile?.mdStatus;
            break;
    }

    //Determine blogposts and quoteid for payload
    const blogPosts = determineBlogPostsAndQuotes(determiningFactor, statusToCheck);

    //Construct the default payload
    const payload = {
        // represents the Up Next box contents
        title: 'Learn more about sleep apnea',
        content:
            'We have many resources to help you learn about sleep apnea. Check them out by clicking the link below.',
        actionText: 'Learn more',
        actionDestination: 'resources',
        // represents the advice quote
        quoteId: blogPosts?.quoteId,
        // represents the post the quote is linked to
        quoteLink: blogPosts?.quoteLink,
        quoteLinkText: blogPosts?.quoteLinkText ?? null,
        // represents the blogposts in the sleep resources
        blogPostIds: blogPosts?.blogpostIds ?? ['untreated-sleep-apnea-impacting-sleep-quality'],
        onClick: undefined,
    };

    //To-do, is this an override?
    if (feedback?.length === 0 && showFeedbackBox) {
        payload.title = 'WE WANT TO HEAR FROM YOU!';
        payload.content = (
            <>
                How has your experience with GEM been? <br />
                <FeedbackThumbs />
            </>
        );
        payload.actionText = undefined;
    }

    //Adjust payload based on determining factor and its matching status
    switch (determiningFactor) {
        case 'beingDiagnosed': {
            switch (user.patientProfile?.mdStatus) {
                case 'ITAMAR_HST_NEEDS_REVIEW':
                    payload.title = 'Sleep Study Under Review!';
                    payload.content =
                        "We got your test! Our GEM SLEEP Providers are reviewing your sleep data, we'll alert you when the results are ready.";
                    payload.actionText = 'Sleep Support Resources';
                    payload.actionDestination = 'resources';
                    break;
                case 'HST_COMPLETE':
                    payload.title = 'Sleep Study Review Complete!';
                    payload.content =
                        'Your GEM Provider has reviewed your sleep study. Your results are ready for you.';
                    payload.actionText = 'View my Sleep Study Results';
                    payload.actionDestination = '/dashboard/diagnosis';
                    break;
                case 'UPLOADED_SLEEP_STUDY_INCOMPLETE':
                    payload.title = 'New Sleep Study Required';
                    payload.content =
                        'The uploaded sleep study you provided has missing information that we need to move ahead with therapy, a new sleep study is required.';
                    payload.actionText = 'Get at-home sleep study';
                    payload.actionDestination = 'diagnosis/gem-sleep-study';
                    break;
                case 'UPLOADED_SLEEP_STUDY_PENDING_CLINICAL_REVIEW':
                    payload.title = 'Sleep Study Uploaded';
                    payload.content =
                        'Your sleep study has been uploaded and is being reviewed by a GEM SLEEP provider.';
                    payload.actionText = '';
                    break;
                case 'UPLOADED_SLEEP_STUDY_APPROVED':
                    payload.title = 'Sleep Study Review Complete!';
                    payload.content =
                        'Your GEM Provider has reviewed your sleep study. Your results are ready for you.';
                    payload.actionText = 'View my Sleep Study Results';
                    payload.actionDestination = '/dashboard/diagnosis';
                    break;
                //These statuses are not included in this case
                case 'NOT_INITIATED':
                case 'PENDING_CLINICAL_REVIEW':
                case 'HST_LAB_ORDER_COMPLETE':
                case 'ITAMAR_HST_REVIEW_COMPLETE':
                case 'CLINICIAN_TICKET_CREATED':
                default:
                    break;
            }
            break;
        }
        case 'hstOrder': {
            switch (hstOrder.fulfillmentStatus) {
                case 'NEW':
                case 'READY_FOR_FULFILLMENT':
                case 'ORDERED':
                case 'READY_TO_SHIP':
                    payload.title = 'Home Sleep Study Ordered';
                    payload.content =
                        'We have your Home Sleep Study order! Check your email or back here for updates on your order status!';
                    payload.actionText = 'Home Sleep Study: How to Guide';
                    payload.actionDestination = 'https://www.mygemsleep.com/hst-how-to-guide';
                    break;
                case 'SHIPPED':
                    payload.title = 'Your Home Sleep Study Is On The Way!';
                    payload.content =
                        'Great news! Your sleep study order has shipped, check your email for tracking information. In the meantime please review our Home Sleep Study: How to Guide';
                    payload.actionText = 'Home Sleep Study: How to Guide';
                    payload.actionDestination = 'https://www.mygemsleep.com/hst-how-to-guide';
                    break;
                case 'DELIVERED':
                    payload.title = 'Time to Test your Sleep';
                    payload.content =
                        'Your sleep study has been delivered. It is time to test -- click below to read the instructions on how to set-up your WatchPat device.';
                    payload.actionText = 'Home Sleep Study: How to Guide';
                    payload.actionDestination = 'https://www.mygemsleep.com/hst-how-to-guide';
                    break;
                case 'BACKORDERED':
                    payload.title = 'An item from your order is on backorder!';
                    payload.content =
                        'We are working as fast as possible to get the item back in stock. Your order will ship as soon as it is available. For more details please reach out to our support team.';
                    payload.actionText = 'Contact Us';
                    payload.actionDestination = undefined;
                    payload.onClick = (ev) => dispatch(toggleModal('contact'));
                    break;
                default:
                    break;
            }
            break;
        }
        // case 'inTreatment': {
        //     if (treatmentInCompliance) {
        //         //To-Do
        //     } else {
        //         //To-Do
        //     }
        //     break;
        // }
        case 'cpapOrder': {
            switch (cpapOrder.fulfillmentStatus) {
                case 'NEW':
                    switch (cpapOrder.estimateStatus) {
                        case 'ESTIMATE_WITH_PATIENT':
                            payload.title = 'Your CPAP Estimate is Ready, time to review!';
                            payload.content =
                                'Download your estimate to review the details. Once reviewed please approve, approval is required for your CPAP to be shipped.';
                            payload.actionText = 'Click to Download';
                            //To-do, determine action destination
                            payload.actionDestination = null;
                            break;
                        case 'ESTIMATE_PATIENT_APPROVED':
                            break;
                        case 'ESTIMATE_REJECTED':
                            break;
                        default:
                            payload.title = `We're preparing your CPAP order!`;
                            payload.content =
                                'The sleep supplies you ordered are being prepared for shipment. We will email you when they have shipped.';
                            payload.actionText = 'Sleep Support Resources';
                            payload.actionDestination = 'resources';
                            break;
                    }
                    break;
                case 'READY_FOR_FULFILLMENT':
                case 'ORDERED':
                case 'READY_TO_SHIP':
                    payload.title = `We're preparing your CPAP order!`;
                    payload.content =
                        'The sleep supplies you ordered are being prepared for shipment. We will email you when they have shipped.';
                    payload.actionText = 'Sleep Support Resources';
                    payload.actionDestination = 'resources';
                    break;
                case 'SHIPPED':
                    payload.title = 'Better Sleep in TRANSIT!';
                    payload.content =
                        'Yay! The sleep supplies you ordered are in route to the shipping address provided! Check your email for a tracking number to follow your order.';
                    payload.actionText = 'Sleep Support Resources';
                    payload.actionDestination = 'resources';
                    break;
                case 'BACKORDERED':
                    payload.title = 'An item from your order is on backorder!';
                    payload.content =
                        'We are working as fast as possible to get the item back in stock. Your order will ship as soon as it is available. For more details please reach out to our support team.';
                    payload.actionText = 'Contact Us';
                    payload.actionDestination = undefined;
                    payload.onClick = (ev) => dispatch(toggleModal('contact'));
                    break;
                case 'DELIVERED':
                    payload.title = 'Better sleep has arrived!';
                    payload.content =
                        'Your CPAP has been delivered. Next Step: use our step-by-step guide to set-up your machine, and find time to meet with your coach.';
                    payload.actionText = 'Learn How to Set-up Your CPAP';
                    payload.actionDestination =
                        cpapBrand === 'react'
                            ? 'https://support.mygemsleep.com/cpap-get-started-guide-react'
                            : 'https://support.mygemsleep.com/cpap_start_guide?test=true';
                    break;
                default:
                    break;
            }
            break;
        }
        case 'suppliesOrder': {
            switch (suppliesOrder.fulfillmentStatus) {
                case 'NEW':
                case 'READY_FOR_FULFILLMENT':
                case 'ORDERED':
                case 'READY_TO_SHIP':
                    payload.title = 'CPAP Supply Order Check!';
                    payload.content =
                        'We are in the process of boxing up your supplies for better sleep! Check your email or back here for additional updates on the status of your order.';
                    payload.actionText = 'Sleep Support Resources';
                    payload.actionDestination = 'resources';
                    break;
                case 'SHIPPED':
                    payload.title = 'Your new CPAP supplies are on the way!';
                    payload.content =
                        'Check your email for tracking number and current package status!';
                    payload.actionText = 'View CPAP Cleaning Schedule ';
                    payload.actionDestination = 'resources/blog/cpap-parts-replacement-schedule';
                    break;
                case 'DELIVERED':
                    payload.title = 'Your CPAP supplies have been delivered!';
                    payload.content =
                        'Set yourself a reminder to swap out your supplies on a set cadence for the best sleep!';
                    payload.actionText = 'View CPAP Cleaning Schedule ';
                    payload.actionDestination = 'resources/blog/cpap-parts-replacement-schedule';
                    break;
                case 'BACKORDERED':
                    payload.title = 'An item from your order is on backorder!';
                    payload.content =
                        'We are working as fast as possible to get the item back in stock. Your order will ship as soon as it is available. For more details please reach out to our support team.';
                    payload.actionText = 'Contact Us';
                    payload.actionDestination = undefined;
                    payload.onClick = (ev) => dispatch(toggleModal('contact'));
                    break;
                default:
                    break;
            }
            break;
        }
        case 'dentalOfficeHST': {
            payload.title = 'Thanks for Creating Your GEM Account!';
            payload.content =
                'Show your dentist the confirmation screen. They will get you a home sleep test device to take home.';
            payload.actionText = 'Show My Confirmation';
            payload.actionDestination = 'treatment/dentist-confirmation';
            break;
        }
        case 'dmeReferral': {
            switch (dmeReferral.referralStatus) {
                case 'REQUESTED':
                    payload.title = "You're being referred";
                    payload.content =
                        "We are preparing your referral to your insurer's CPAP provider. If you decide to instead order a CPAP with GEM you still can.";
                    payload.actionText = 'Take me There!';
                    payload.actionDestination = 'treatment';
                    break;
                case 'REFERRED':
                    payload.title = "You've been referred";
                    payload.content =
                        "We have sent your referral to your insurer's CPAP provider. If you decide to instead order a CPAP with GEM you still can.";
                    payload.actionText = 'Take me There!';
                    payload.actionDestination = 'treatment';
                    break;
                case 'CANCELLED':
                    payload.title = 'Time to Take Treatment Action';
                    payload.content =
                        "It's time to take action on your personalized treatment recommendation! We will be there every step of the way.";
                    payload.actionText = 'Take me There!';
                    payload.actionDestination = 'treatment';
                    break;
                default:
                    break;
            }
            break;
        }
        case 'coachingStatus':
            switch (user.patientProfile?.coachingStatus) {
                case 'COACHING_REQUESTED':
                    payload.title = 'Complete your Coaching Intake Form!';
                    payload.content =
                        'Prior to enrollment in the coaching program, we need a little bit more information. Check your email to complete the required intake forms.';
                    payload.actionText = uploadedCharts ? null : 'Upload Documents';
                    payload.actionDestination = uploadedCharts ? null : 'diagnosis/study-upload';
                    break;
                case 'SUPPORT_REVIEW_NEEDED':
                    payload.title = 'Under Review: Enrollment into GEM Coaching Program';
                    payload.content =
                        'We received your documents. Once review is completed you will be alerted with the next steps. Complete form sent to you via email.';
                    payload.actionText = null;
                    payload.actionDestination = null;
                    break;
                case 'CLINICAL_REVIEW_NEEDED':
                    payload.title = 'Under Review: Enrollment into GEM Coaching Program';
                    payload.content =
                        'We received your documents. Once review is completed you will be alerted with the next steps.';
                    payload.actionText = null;
                    payload.actionDestination = null;
                    break;
                case 'APPROVED':
                    payload.title = `YOU'RE IN! WELCOME TO GEM COACHING!`;
                    payload.content = `Let's get you back on track with CPAP therapy using GEM SLEEP. You have been assigned ${coachsName} as your personal coach.`;
                    payload.actionText = 'Review NEXT STEPS';
                    payload.actionDestination = 'treatment';
                    break;
                case 'NOT_APPROVED':
                    payload.title = `PROVIDER REVIEW COMPLETE`;
                    payload.content = null;
                    payload.actionText = 'Review Recommendation';
                    payload.actionDestination = 'treatment';
                    break;
                case 'NOT_INITIATED':
                default:
                    break;
            }
            break;
        case 'cpapInCart':
            payload.title = 'Time to Take Treatment Action';
            payload.content =
                "It's time to take action on your personalized treatment recommendation! We will be there every step of the way.";
            payload.actionText = 'Take me There!';
            payload.actionDestination = 'treatment';
            break;
        case 'studyUploadPath':
            payload.title = 'UPLOAD NOW';
            payload.content =
                'In order to continue ordering a CPAP or supplies, we must first review your prior documents.'; // TO-DO Needs new copy.
            payload.actionText = 'Take me There!';
            payload.actionDestination = 'diagnosis/study-upload';
            break;
        case 'patientPath':
            payload.title = 'Do you have Sleep Apnea?';
            payload.content = 'Complete a Home Sleep Study test with just one night of sleep!';
            payload.actionText = 'Order Home Sleep Test';
            payload.actionDestination = 'diagnosis/gem-sleep-study';
            break;
        default:
            break;
    }

    //Override payload if user has an upcoming, cancelled, or missed appointment
    if (appointmentScheduledOrCancelled) {
        switch (currentAppointment.appointmentStatus) {
            case 'SCHEDULED':
                switch (currentAppointment.appointmentType) {
                    default:
                        const { start } = formatAppointmentForDisplay(currentAppointment);

                        payload.title = 'Appointment Reminder!';
                        payload.content =
                            'You have an upcoming appointment. Please cancel or make changes to your appointment 24 hours prior.';
                        payload.actionText = start;
                        payload.actionDestination = '/dashboard/appointments/my-appointment';
                        payload.blogpostIds = [
                            'what-is-your-circadian-rhythm-and-how-does-it-affect-your-health-and-daily-function',
                            'how-sleep-impacts-your-brain-health',
                        ];
                        payload.quoteId = 'virtual-visit';
                        payload.quoteLink = 'https://www.youtube.com/watch?v=8v9W_89iR54';
                        break;
                }
                break;
            case 'CANCELLED':
                switch (currentAppointment.appointmentType) {
                    case 'Initial Consultation':
                        payload.title = 'Canceled Appointment';
                        payload.content =
                            'You have canceled your appointment. Please reschedule your virtual visit. Your Home Sleep test order has been put on <b>hold</b>, the test will ship once the visit is completed.';
                        payload.actionText = 'Reschedule Now';
                        payload.actionDestination =
                            '/dashboard/appointments/reschedule-appointment';
                        payload.actionStateObject = {
                            from: 'appointmentRescheduling',
                            appointmentToReschedule: currentAppointment,
                        };
                        payload.blogpostIds = [
                            'how-sleep-apnea-impacts-the-body',
                            'how-much-better-you-could-feel',
                        ];
                        payload.quoteLink = '/dashboard/appointments/reschedule-appointment';
                        payload.quoteId = 'virtual-visit---reschedule';
                        payload.quoteLinkText = 'Book A New Time';
                        payload.quoteLinkIsInternal = true;
                        break;
                    default:
                        payload.title = 'Canceled Appointment';
                        payload.content =
                            'You have canceled your appointment. Please reschedule your virtual visit.';
                        payload.actionText = 'Reschedule Now';
                        payload.actionDestination =
                            '/dashboard/appointments/reschedule-appointment';
                        payload.actionStateObject = {
                            from: 'appointmentRescheduling',
                            appointmentToReschedule: currentAppointment,
                        };
                        payload.quoteLink = '/dashboard/appointments/reschedule-appointment';
                        payload.quoteId = 'virtual-visit---reschedule';
                        payload.quoteLinkText = 'Book A New Time';
                        payload.quoteLinkIsInternal = true;
                        break;
                }
                break;
            case 'NOSHOW':
                switch (currentAppointment.appointmentType) {
                    case 'Initial Consultation':
                        payload.title = 'We Missed You At Your Appointment!';
                        payload.content =
                            'We missed you at your scheduled appontment. Please find a time to reschedule.  Your Home Sleep test order has been put on <b>hold</b>, the test will ship once the visit is completed. ';
                        payload.actionText = 'Reschedule Now';
                        payload.actionDestination =
                            '/dashboard/appointments/reschedule-appointment';
                        payload.actionStateObject = {
                            from: 'appointmentRescheduling',
                            appointmentToReschedule: currentAppointment,
                        };
                        payload.blogPostIds = [
                            'how-sleep-apnea-impacts-the-body',
                            'how-much-better-you-could-feel',
                        ];
                        payload.quoteLink = '/dashboard/appointments/reschedule-appointment';
                        payload.quoteId = 'virtual-visit---reschedule';
                        payload.quoteLinkText = 'Book A New Time';
                        payload.quoteLinkIsInternal = true;
                        break;
                    default:
                        payload.title = 'We Missed You At Your Appointment!';
                        payload.content =
                            'Please follow the link to reschedule your virtual appointment. If you have quesitons about how to join the appointment or download Zoom please contact us.';
                        payload.actionText = 'Reschedule Now';
                        payload.actionDestination =
                            '/dashboard/appointments/reschedule-appointment';
                        payload.actionStateObject = {
                            from: 'appointmentRescheduling',
                            appointmentToReschedule: currentAppointment,
                        };
                        payload.blogPostIds = ['how-much-better-you-could-feel'];
                        payload.quoteLink = '/dashboard/appointments/reschedule-appointment';
                        payload.quoteId = 'virtual-visit---reschedule';
                        payload.quoteLinkText = 'Book A New Time';
                        payload.quoteLinkIsInternal = true;
                        break;
                }
                break;
            default:
                break;
        }
    }

    //Override payload if user has supplies which need reorder
    if (
        currentSupplies &&
        currentSupplies.some(
            (supply) => Object.hasOwn(supply, 'needsReorder') && supply.needsReorder,
        ) &&
        !user?.resupply.some((resupply) => resupply.enrollmentStatus === 'ENROLLED')
    ) {
        payload.title = 'Time to Order Supplies';
        payload.content = 'Your CPAP supplies and mask need to be replaced to work their best!';
        payload.actionText = 'RE-ORDER SUPPLIES';
        payload.actionDestination = 'treatment';
        payload.quoteId = 'dr-mike-howell-3';
        payload.quoteLink = 'caring-for-your-cpap';
        payload.blogPostIds = [
            'cpap-parts-replacement-schedule',
            'traveling-with-your-cpap-machine',
        ];
    }

    //Override payload if user needs a compliance visit (compliant without having completed one)
    if (userNeedsComplianceVisit) {
        payload.title = 'Time to Schedule your Compliance Visit!';
        payload.content =
            'This visit is required by your insurance company for continued payment on your CPAP Machine.';
        payload.actionText = 'Click to Schedule';
        payload.actionDestination = null;
        payload.actionSchedulingSku = 'COMPLIANCE';
        payload.quoteId = 'compliance-visit';
        payload.blogPostIds = ['gem-sleeps-cpap-tips-tricks', 'traveling-with-your-cpap-machine'];
    }

    //Override payload if user has estimates to approve
    if (orderWithEstimateToApprove) {
        const { isCpapOrder } = determineOrderCategories(orderWithEstimateToApprove);

        if (isCpapOrder) {
            payload.title = 'Your CPAP Estimate is Ready, time to review!';
            payload.content =
                'Download your estimate to review the details. Once reviewed please approve, approval is required for your CPAP to be shipped.';
            payload.actionText = 'Click to Download';
            payload.actionDestination = `orders/estimate/${orderWithEstimateToApprove.estimate.id}`;
        }
    }

    return payload;
};
