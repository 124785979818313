import { AppointmentSchedulingButton } from '../Appointments';

function ComplianceCallout() {
    return (
        <div className="mb-7 lg:flex">
            <div className="flex-1 bg-black flex items-center flex-col sm:flex-row justify-center lg:justify-normal p-9 lg:p-4 gap-4">
                <p className="font-bold text-white lg:text-lg">
                    You are due for a visit with a GEM CLINICIAN, schedule today.
                </p>
            </div>
            <AppointmentSchedulingButton
                label="Schedule"
                sku="COMPLIANCE"
                styling="btn-primary w-full lg:w-[250px] inline-flex items-center justify-center"
            />
        </div>
    );
}

export default ComplianceCallout;
