import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../app/services/auth';
import { hasRole } from '../../utils/roleUtils';
import posthog from 'posthog-js';

const valuesToToggleOverflowHidden = [
    'accountOpen',
    'cartOpen',
    'navOpen',
    'editScreenOpen',
    'passwordResetScreenOpen',
    'orderHistoryOpen',
];

const initialState = {
    accountOpen: false,
    agreedToInsuranceBenefits: false,
    appointmentBooking: {
        appointmentToReschedule: null,
    },
    cartOpen: false,
    currentAppointment: null,
    dmeReferral: null,
    navOpen: false,
    editScreenOpen: false,
    enrolledInResupply: false,
    isCoachingOnlyPath: false,
    lastRefresh: null,
    modalsOpen: [],
    passwordResetScreenOpen: false,
    orderHistoryOpen: false,
    showFeedbackBox: false,
    simpleDynamicModalMessage: '',
    sovaSageConsultLink: null,
    supportFilters: {
        categories: [],
        type: [],
        dropdownsOpen: [],
    },
    userNeedsComplianceVisit: false,
    wore7days: null,
    wore15days: null,
    wore30days: null,
};

function updateStateWithUserObject(state, user) {
    //Sort appointments into reverse chronological order
    const sortedAppointments = user.appointments
        .filter((a) => a.confirmed && a.order?.orderStatus !== 'CANCELLED')
        .sort((a1, a2) => new Date(a2.createdAt) - new Date(a1.createdAt));

    //Find the most recently created appointment
    if (sortedAppointments.length > 0) {
        state.currentAppointment = sortedAppointments[0];
    } else if (
        //Otherwise they may be a new user
        //Provide welcome modal to users who have not had any mdStatus set and are still at NOT_INITIATED
        //(PENDING_CLINICAL_REVIEW is set for HST Orders and UPLOADED_SLEEP_STUDY_PENDING_CLINICAL_REVIEW for Study uploads)
        user.patientProfile.mdStatus === 'NOT_INITIATED'
    ) {
        state.modalsOpen.push('welcomeModal');
    }

    //Check for upcoming appointments, and find the closest one
    const scheduledAppointments = sortedAppointments
        .filter((a) => a.appointmentStatus === 'SCHEDULED')
        .sort((a1, a2) => new Date(a2.startAt) - new Date(a1.startAt));

    if (scheduledAppointments.length > 0) {
        state.upcomingAppointment = scheduledAppointments[0];
    } else {
        state.upcomingAppointment = null;
    }

    //Determine if they have a DME Referral
    if (user.referrals.length > 0) {
        state.dmeReferral = user.referrals.find((referral) =>
            ['REFERRED', 'REQUESTED'].includes(referral.referralStatus),
        );
    }

    //Determine if user is on coaching path
    state.isCoachingOnlyPath = user.patientProfile.coachingStatus !== 'NOT_INITIATED';

    //Determine if user is enrolled in the resupply program
    state.enrolledInResupply = user.resupply?.some(
        (resupply) => resupply.enrollmentStatus === 'ENROLLED',
    );

    //Determine if the user needs a compliance visit
    state.userNeedsComplianceVisit =
        user?.patientProfile?.treatments.some(
            (treatment) => treatment.isActive && treatment.initialCompliance === 'COMPLIANT',
        ) &&
        !sortedAppointments.some(
            (appointment) =>
                appointment.appointmentType === 'Compliance Visit' &&
                ['SCHEDULED', 'OCCURRED'].includes(appointment.appointmentStatus),
        );
}

export const uiSlice = createSlice({
    name: 'ui',
    initialState: initialState,
    reducers: {
        toggleModal: (state, { payload: modalId }) => {
            const targetModalIdIndex = state.modalsOpen.indexOf(modalId);

            posthog?.capture('Toggled Modal', {
                id: modalId,
            });

            targetModalIdIndex > -1
                ? state.modalsOpen.splice(targetModalIdIndex, 1)
                : state.modalsOpen.push(modalId);
        },
        toggle: (state, { payload }) => {
            const { key, override } = payload;

            const newState = override !== undefined ? override : !state[key];

            state[key] = override !== undefined ? override : !state[key];

            if (valuesToToggleOverflowHidden.includes(key)) {
                newState
                    ? document.body.classList.add('overflow-hidden')
                    : document.body.classList.remove('overflow-hidden');
            }

            posthog?.capture('Toggled value', { value: key });
        },
        setLastRefresh: (state, { payload }) => {
            state.lastRefresh = payload;
        },
        setAppointmentBookingData: (state, { payload }) => {
            if (Array.isArray(payload)) {
                payload.forEach((item) => {
                    state.appointmentBooking[item.key] = item.value;
                });
            } else {
                state.appointmentBooking[payload.key] = payload.value;
            }
        },
        setSupportFilters: (state, { payload }) => {
            state.supportFilters[payload.key] = payload.value;
        },
        setSimpleDynamicModalMessage: (state, { payload: message }) => {
            state.simpleDynamicModalMessage = message;
            state.modalsOpen = ['ghSimpleDynamicModal'];
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(api.endpoints.logout.matchFulfilled, (state, action) => {
                return initialState;
            })
            .addMatcher(
                api.endpoints.createSovasageConsult.matchFulfilled,
                (state, { payload }) => {
                    //The payload returns a link to their sovaSage consult fitting
                    state.sovaSageConsultLink = payload.consultLink;
                },
            )
            .addMatcher(api.endpoints.login.matchFulfilled, (state, { payload }) => {
                // it would be nice to consolidate this with the getMe fulfilled matcher
                state.lastRefresh = Date.now();
                if (!payload.user || hasRole(payload.user.userRoles, 'MD')) return;

                if (payload.user.patientProfile?.treatmentSupportStatus === 'WORE_7_DAYS')
                    state.wore7days = true;
                if (payload.user.patientProfile?.treatmentSupportStatus === 'WORE_15_DAYS')
                    state.wore15days = true;
                if (payload.user.patientProfile?.treatmentSupportStatus === 'WORE_30_DAYS')
                    state.wore30days = true;

                updateStateWithUserObject(state, payload.user);
            })
            .addMatcher(api.endpoints.getMe.matchFulfilled, (state, { payload }) => {
                if (hasRole(payload.userRoles, 'MD')) return;

                updateStateWithUserObject(state, payload);
            })
            .addMatcher(
                api.endpoints.getRecommendedCPAPBundles.matchFulfilled,
                (state, { payload }) => {
                    if (payload?.length > 0) {
                        //If they have requested a sovaSageConsultLink, they are pending review and waiting for the recommendations
                        if (state.sovaSageConsultLink) {
                            state.modalsOpen = ['sovaSageCompleted'];
                        }
                    }
                },
            )
            .addMatcher(api.endpoints.refreshTokens.matchFulfilled, (state) => {
                state.lastRefresh = Date.now();
            })
            .addMatcher(api.endpoints.getUserHistory.matchFulfilled, (state, { payload }) => {
                const treatmentSupportStatuses = new Set();
                payload?.forEach((r) =>
                    r.changes?.treatmentSupportStatus?.forEach((status) =>
                        treatmentSupportStatuses.add(status),
                    ),
                );
                state.wore30days = treatmentSupportStatuses?.has('WORE_30_DAYS');
                state.wore15days = treatmentSupportStatuses?.has('WORE_15_DAYS');
                state.wore7days = treatmentSupportStatuses?.has('WORE_7_DAYS');
                if (
                    !localStorage.getItem('wore7days') &&
                    treatmentSupportStatuses?.has('WORE_7_DAYS')
                ) {
                    localStorage.setItem('wore7days', 'true');
                    state.modalsOpen.push('7days');
                }
                if (
                    !localStorage.getItem('wore15days') &&
                    treatmentSupportStatuses?.has('WORE_15_DAYS')
                ) {
                    localStorage.setItem('wore15days', 'true');
                    state.modalsOpen.push('15days');
                }
                if (
                    !localStorage.getItem('wore30days') &&
                    treatmentSupportStatuses?.has('WORE_30_DAYS')
                ) {
                    localStorage.setItem('wore30days', 'true');
                    state.modalsOpen.push('30days');
                }
            })
            .addMatcher(api.endpoints.getUserHistory.matchFulfilled, (state, { payload }) => {
                const reviewedSleepStudyStatusEvent = payload?.find(
                    (item) =>
                        item.action === 'update' &&
                        item.changes?.mdStatus &&
                        ['HST_COMPLETE', 'UPLOADED_SLEEP_STUDY_APPROVED'].includes(
                            item.changes?.mdStatus[1],
                        ),
                );

                if (reviewedSleepStudyStatusEvent) {
                    state.showFeedbackBox = true;
                }
            })
            .addMatcher(api.endpoints.getOrderHistory.matchFulfilled, (state, { payload }) => {
                if (!payload) {
                    return;
                }
                const treatmentSelectedEvent = payload.some(
                    (order) =>
                        order.orderStatus === 'COMPLETED' &&
                        order.orderItems.some((p) => p.itemCategory === 'CPAP_SUPPLIES'),
                );

                if (treatmentSelectedEvent) {
                    state.showFeedbackBox = true;
                }
            })
            .addMatcher(api.endpoints.getPatientConsents.matchFulfilled, (state, { payload }) => {
                state.agreedToInsuranceBenefits = payload?.results?.find(
                    (c) => c.documentType === 'assignment-of-benefit-responsibility',
                );
            });
    },
});

export const {
    confirmCurrentAppointment,
    setAppointmentBookingData,
    setLastRefresh,
    setSimpleDynamicModalMessage,
    setSupportFilters,
    toggle,
    toggleModal,
} = uiSlice.actions;

export default uiSlice.reducer;
