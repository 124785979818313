function Dialog({ children, open, setOpen }) {
    return (
        <dialog
            open={open ? open : true}
            className="fixed top-0 left-0 right-0 bottom-0 z-50 w-full h-full flex flex-col items-center justify-center bg-purple-dark/80 backdrop-blur-xl"
            onClick={() => setOpen(false)}>
            {children}
        </dialog>
    );
}

export default Dialog;
