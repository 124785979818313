import { useDispatch, useSelector } from 'react-redux';
import posthog from 'posthog-js';
import ReactGA from 'react-ga4';
import { setAssessmentStep } from '../../../features/assessment/assessmentSlice';

function CoachingResult() {
    const dispatch = useDispatch();
    const { answers } = useSelector((state) => state.assessment);

    const hasValidPriorStudy =
        answers.previouslyDiagnosedWithSleepApnea &&
        answers.hasPriorStudy &&
        answers.howBroughtToGem !== 'NEED_NEW_SLEEP_TEST' &&
        !answers.gained20Lbs;

    return (
        <div className="max-w-5xl mx-auto gap-4 lg:gap-8">
            <h4 className="mb-4 font-heading text-3xl text-purple-fair lg:mb-6">
                ASSESSMENT RESULTS
            </h4>
            <h2 className="font-heading text-4xl font-bold text-white lg:text-7xl">
                Good News! Based on your preliminary intake you may be eligible for the GEM SLEEP
                Coaching Program.
            </h2>

            <div className="flex flex-col text-sm space-y-4 lg:flex-col-reverse">
                <button
                    className="btn-primary w-full text-lg mt-5"
                    onClick={() => {
                        posthog?.capture('Answered a Question', {
                            question: 'Assessment Result',
                            answer: 'continue',
                        });
                        ReactGA.event({
                            category: 'User',
                            action: 'Assessment Result',
                            label: 'Continue_Result',
                        });
                        dispatch(setAssessmentStep('create-account'));
                    }}>
                    CONTINUE
                </button>
                <div className="text-sm lg:text-xl">
                    <p>
                        To continue with the intake process please create a GEM SLEEP Account.{' '}
                        {hasValidPriorStudy
                            ? 'After creating an account you will be asked to upload the required documents.'
                            : 'To qualify for the program you will first need to complete a new Home Sleep Test. Click continue to create your GEM Account.'}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default CoachingResult;
