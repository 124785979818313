import { motion } from 'framer-motion';
import { overlayVariants } from '../../../utils/constants';
import { useCheckoutContext } from '../../Checkout/CheckoutContext';

const CheckCoverage = ({ hasInsurance }) => {
    const { setCheckoutStep, skipInsurance } = useCheckoutContext();

    return (
        <motion.div initial="hidden" animate="visible" exit="hidden" variants={overlayVariants}>
            <h5 className="mb-5 font-heading text-4xl font-bold lg:text-5xl">
                Would you like to check if your insurance works with GEM?
            </h5>
            <ul className="flex items-center space-x-4 lg:flex-col lg:space-x-0 lg:space-y-4">
                <li className="w-full">
                    <button
                        onClick={() => {
                            if (hasInsurance) {
                                setCheckoutStep('insurance-review');
                            } else {
                                setCheckoutStep('insurance-details');
                            }
                        }}
                        className="btn-shell block w-full">
                        Yes
                    </button>
                </li>
                <li className="w-full">
                    <button
                        onClick={() => {
                            skipInsurance({ showAlert: true });
                        }}
                        className="btn-shell block w-full">
                        No
                    </button>
                </li>
            </ul>
            <p className="mt-4">
                If you have insurance but don't know it, please call us at{' '}
                <a href="tel:+18339464436" className="font-bold">
                    1-833-946-4436
                </a>
            </p>
        </motion.div>
    );
};
export default CheckCoverage;
