import { useParams } from 'react-router-dom';

import {
    GeneralOrderInformation,
    OrderAppointments,
    OrderChanges,
    OrderItems,
    SelectItemFromCatalog,
    // AddShippingDetails,
    // ShippingDetails,
} from '../../Orders';

import {
    useAddOrderItemMutation,
    useGetOrderInfoQuery,
} from '../../../../app/services/admin/orders';
import { adminItemsSelector } from '../../../../features/catalog/catalogSlice';
import { useSelector } from 'react-redux';
import { HorizontalLine } from '../../../LayoutComponents';
import { useState } from 'react';

const OrderInformation = () => {
    //Order information from clicked order
    const { orderId, patientId } = useParams();

    //Expanded order information through fetch
    const { data: order } = useGetOrderInfoQuery(
        {
            userId: patientId,
            orderId: orderId,
        },
        { skip: !patientId || !orderId },
    );

    const [addOrderItem] = useAddOrderItemMutation();
    const addItemOptions = useSelector(adminItemsSelector);

    const [orderChangesOpen, setOrderChangesOpen] = useState(false);

    if (!order) return <p className="py-6 font-heading text-5xl">Loading...</p>;
    else
        return (
            <div>
                <div className="flex justify-between">
                    <p className="font-heading text-xl lg:text-3xl min-w-[140px]">
                        General Information
                    </p>
                    <button
                        className="btn-shell-small hover:text-gray hover:bg-gray-dark"
                        onClick={() => setOrderChangesOpen(true)}>
                        View Order Changes
                    </button>
                </div>
                <GeneralOrderInformation order={order} />
                <HorizontalLine styling="col-span-4 my-1 text-gray-dark" />
                <p className="font-heading text-xl lg:text-3xl min-w-[140px]">Order Items</p>
                <OrderItems
                    key={`order-items.component-${order?.id}`}
                    order={order}
                    patientId={patientId}
                />
                <SelectItemFromCatalog
                    key={`add-order-items.component-${order?.id}`}
                    includeQuantity={true}
                    itemOptions={addItemOptions}
                    label={'Add Item'}
                    successToast="Item Added to Order"
                    onSubmit={(values) =>
                        addOrderItem({
                            orderId: orderId,
                            userId: patientId,
                            values: values,
                        })
                    }
                />

                {/* <HorizontalLine styling="col-span-4 my-1 text-gray-dark" />{' '}
                <p className="font-heading text-xl lg:text-3xl min-w-[140px]">Shipping</p>
                <ShippingDetails order={order} patientId={patientId} />
                <AddShippingDetails
                    key={`add-shipping-details-component`}
                    patientId={patientId}
                    order={order}
                /> */}

                <HorizontalLine styling="col-span-4 my-1 text-gray-dark" />
                <p className="font-heading text-xl lg:text-3xl min-w-[140px]">Appointments</p>
                <OrderAppointments order={order} />
                {orderChangesOpen && (
                    <OrderChanges
                        orderId={order?.id}
                        orderChangesOpen={orderChangesOpen}
                        setOrderChangesOpen={setOrderChangesOpen}
                    />
                )}
            </div>
        );
};

export default OrderInformation;
