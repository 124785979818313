import { useLocation } from 'react-router';
import { useEffect } from 'react';
import Modal from '../Modal/Modal';
import { useInsuranceContext } from './InsuranceContext';

function getModalContent(insurance) {
    if (!insurance)
        return (
            <p className="mb-4">
                We do NOT have insurance information on file for you. Let’s walk you through the
                steps of getting a CPAP. If you would like to check coverage with your insurance you
                can enter it during checkout.
            </p>
        );
    else {
        if (insurance.payer?.dmeReferralOrganizationId !== null)
            return (
                <>
                    <p className="mb-4">
                        It looks like you entered {insurance.payer?.name} insurance. For CPAP, you
                        have two coverage options.
                    </p>
                    <ul>
                        <li className="mb-4">
                            1. To purchase your CPAP using your {insurance.payer?.name} insurance,
                            GEM SLEEP can send a referral to {insurance.payer?.name}
                            's CPAP provider.
                        </li>
                        <li className="mb-4">
                            2. You can place your order directly through GEM SLEEP without
                            insurance.
                        </li>
                    </ul>
                </>
            );
        else if (insurance.payer?.CPAPCovered === true)
            return (
                <p className="mb-4">
                    Based on the insurance information you previously entered, you should be able to
                    use this insurance for CPAP. When you order, we will confirm your insurance is
                    still active and submit a claim on your behalf.
                </p>
            );
        else
            return (
                <p className="mb-4">
                    Based on the insurance information you previously entered, your health plan does
                    not cover CPAP with GEM SLEEP. You can still order your CPAP through GEM SLEEP
                    without insurance. Or you can use your prescription at an in-network DME
                    supplier. We will walk you through each option.
                </p>
            );
    }
}

const CPAPCoveragePaymentChoiceModal = ({ modalOpen, setModalOpen, handleContinueClick }) => {
    const location = useLocation();
    const { insurance } = useInsuranceContext();

    useEffect(() => {
        if (location?.state?.from === '/dashboard/insurance/add') setModalOpen(true);
    }, [location?.state?.from, setModalOpen]);

    function handleClose() {
        setModalOpen(false);
    }
    const modalContent = getModalContent(insurance);
    return (
        <Modal showClose={true} isOpen={modalOpen} closeHandler={() => handleClose()}>
            <h5 className="mb-3 font-heading text-4xl lg:text-5xl">
                Your Insurance Coverage Status
            </h5>
            {modalContent}

            <button
                className="btn-primary w-full"
                onClick={() => {
                    handleContinueClick();
                }}>
                <span>Continue</span>
            </button>
        </Modal>
    );
};

export default CPAPCoveragePaymentChoiceModal;
