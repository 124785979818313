import { ReactComponent as Calendar } from '../../images/icons/calendar.svg';
import { ReactComponent as Clock } from '../../images/icons/clock.svg';
import { ReactComponent as Phone } from '../../images/icons/phone.svg';
import { ReactComponent as SpeechBubble } from '../../images/icons/speech-bubble.svg';
import { formatAppointmentForDisplay } from '../../utils/constants';

const AppointmentBoxListItems = ({ appointment }) => {
    const { name, start, timeframe } = formatAppointmentForDisplay(appointment);

    return (
        <>
            <li className="flex items-center">
                <SpeechBubble className="mr-4" /> {name}
            </li>
            <li className="flex items-center">
                <Calendar className="mr-4" /> {start}
            </li>
            <li className="flex items-center">
                <Clock className="mr-4" /> {timeframe}
            </li>
            <li className="flex items-center">
                <Phone className="mr-4" />
                GEM Video Call
            </li>
            {appointment.zoomJoinUrl && (
                <li>
                    <a
                        href={appointment.zoomJoinUrl}
                        target="_blank"
                        rel="noreferrer"
                        className="border-b-2 border-gem-green font-bold">
                        Launch visit
                    </a>
                </li>
            )}
        </>
    );
};

export default AppointmentBoxListItems;
