import { AnimatePresence } from 'framer-motion';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetUserQuery } from '../../app/services/admin/admin';
import { ConsultList } from '../../components/Admin/Consults';
import {
    CareTeam,
    DocumentsView,
    GEMTab,
    MedicalTab,
    PatientInsurance,
    PatientOrderHistory,
    PatientResupply,
    TreatmentTab,
} from '../../components/Admin/PatientTabs/';
import { toggleModal } from '../../features/ui/uiSlice';

const Patient = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { gemTab } = useParams();

    const { patientId } = useParams();
    const {
        data: patient,
        error,
        isLoading,
        isFetching,
        refetch,
    } = useGetUserQuery(patientId, { skip: !patientId });

    useEffect(() => {
        if (!gemTab) {
            navigate('medical', { replace: true });
        }
    }, [navigate, gemTab]);

    useEffect(() => {
        if (!patient) return;

        const mdStatusForModal = [
            'HST_COMPLETE',
            'UPLOADED_SLEEP_STUDY_APPROVED',
            'ITAMAR_HST_NEEDS_REVIEW',
        ];

        if (
            mdStatusForModal.includes(patient.patientProfile.mdStatus) &&
            !patient.patientProfile.referredForCpap &&
            !patient.patientProfile.isDentalReferred &&
            !patient.patientProfile.needsSleepStudyRetest &&
            !patient.patientProfile.referToSleepClinic
        )
            dispatch(toggleModal('hstNeedsReviewAlert'));
    }, [patient, dispatch]);

    return (
        <AnimatePresence mode="wait">
            {error && <p className="py-6 font-heading text-5xl">Error</p>}
            {!isLoading && !patient && (
                <p className="py-6 font-heading text-5xl">No Patient Found</p>
            )}
            {isLoading ? (
                <p className="py-6 font-heading text-5xl">Loading...</p>
            ) : (
                patient && (
                    <div className="py-6">
                        <h2 className="sticky top-0 z-20 mb-5 flex items-center justify-between bg-purple-dark">
                            <span className="font-heading text-3xl lg:text-5xl">
                                {patient.id} {patient.firstName} {patient.lastName}
                            </span>
                            {patient.nikoId && (
                                <a
                                    className="btn-primary-small"
                                    href={`${process.env.REACT_APP_NIKO_URL}v2/#/patients/${patient.nikoId}`}
                                    target="_blank"
                                    rel="noreferrer">
                                    View in Niko
                                </a>
                            )}
                            {patient.healthieId && (
                                <a
                                    className="btn-primary-small"
                                    href={`${process.env.REACT_APP_HEALTHIE_URL}users/${patient.healthieId}`}
                                    target="_blank"
                                    rel="noreferrer">
                                    View in Healthie
                                </a>
                            )}
                            {patient.itamarPatientId && (
                                <a
                                    className="btn-primary-small"
                                    href={`${process.env.REACT_APP_ITAMAR_URL}PatientsWIthTabs/wp/${patient.itamarPatientId}`}
                                    target="_blank"
                                    rel="noreferrer">
                                    View in Itamar
                                </a>
                            )}
                            <button
                                className="font-heading text-lg tracking-widest text-gray transition hover:text-white"
                                onClick={(ev) => {
                                    navigate(
                                        location?.state?.from ? location?.state?.from : '/admin',
                                    );
                                }}>
                                back to {location?.state?.name ? location?.state?.name : 'Patients'}
                            </button>
                        </h2>

                        <nav className="mb-4 space-y-3">
                            <button
                                onClick={(ev) => {
                                    navigate(`../patients/${patient.id}/medical`, {
                                        replace: true,
                                    });
                                }}
                                className={`border-gray border-b py-2 min-w-[130px] ${gemTab === 'medical' ? 'text-gem-green border-gem-green! font-bold bg-purple-super-dark' : 'hover:text-gem-green hover:font-bold'}`}>
                                Medical
                            </button>
                            <button
                                onClick={(ev) => {
                                    navigate(`../patients/${patient.id}/treatment`, {
                                        replace: true,
                                    });
                                }}
                                className={`border-gray border-b py-2 min-w-[130px] ${gemTab === 'treatment' ? 'text-gem-green border-gem-green! font-bold bg-purple-super-dark' : 'hover:text-gem-green hover:font-bold'}`}>
                                Treatment
                            </button>
                            <button
                                onClick={(ev) => {
                                    navigate(`../patients/${patient.id}/insurance`, {
                                        replace: true,
                                    });
                                }}
                                className={`border-gray border-b py-2 min-w-[130px] ${gemTab === 'insurance' ? 'text-gem-green border-gem-green! font-bold bg-purple-super-dark' : 'hover:text-gem-green hover:font-bold'}`}>
                                Insurance
                            </button>
                            <button
                                onClick={(ev) => {
                                    navigate(`../patients/${patient.id}/gem`, {
                                        replace: true,
                                    });
                                }}
                                className={`border-gray border-b py-2 min-w-[130px] ${gemTab === 'gem' ? 'text-gem-green border-gem-green! font-bold bg-purple-super-dark' : 'hover:text-gem-green hover:font-bold'}`}>
                                GEM
                            </button>
                            <button
                                onClick={(ev) => {
                                    navigate(`../patients/${patient.id}/orderHistory/gem`, {
                                        replace: true,
                                    });
                                }}
                                className={`border-gray border-b py-2 min-w-[130px] ${gemTab === 'orderHistory' ? 'text-gem-green border-gem-green! font-bold bg-purple-super-dark' : 'hover:text-gem-green hover:font-bold'}`}>
                                Orders
                            </button>
                            <button
                                onClick={(ev) => {
                                    navigate(`../patients/${patient.id}/consults`, {
                                        replace: true,
                                    });
                                }}
                                className={`border-gray border-b py-2 min-w-[130px] ${gemTab === 'consults' ? 'text-gem-green border-gem-green! font-bold bg-purple-super-dark' : 'hover:text-gem-green hover:font-bold'}`}>
                                Consults
                            </button>
                            <button
                                onClick={(ev) => {
                                    navigate(`../patients/${patient.id}/careteam`, {
                                        replace: true,
                                    });
                                }}
                                className={`border-gray border-b py-2 min-w-[130px] ${gemTab === 'careteam' ? 'text-gem-green border-gem-green! font-bold bg-purple-super-dark' : 'hover:text-gem-green hover:font-bold'}`}>
                                Care Team
                            </button>
                            <button
                                onClick={(ev) => {
                                    navigate(`../patients/${patient.id}/documents`, {
                                        replace: true,
                                    });
                                }}
                                className={`border-gray border-b py-2 min-w-[130px] ${gemTab === 'documents' ? 'text-gem-green border-gem-green! font-bold bg-purple-super-dark' : 'hover:text-gem-green hover:font-bold'}`}>
                                Documents
                            </button>
                            <button
                                onClick={(ev) => {
                                    navigate(`../patients/${patient.id}/resupply`, {
                                        replace: true,
                                    });
                                }}
                                className={`border-gray border-b py-2 min-w-[130px] ${gemTab === 'resupply' ? 'text-gem-green border-gem-green! font-bold bg-purple-super-dark' : 'hover:text-gem-green hover:font-bold'}`}>
                                Resupply
                            </button>
                        </nav>
                        {gemTab === 'medical' && <MedicalTab patient={patient} />}
                        {gemTab === 'treatment' && <TreatmentTab patient={patient} />}
                        {gemTab === 'gem' && (
                            <GEMTab
                                patient={patient}
                                patientFetching={isFetching}
                                refetch={refetch}
                            />
                        )}
                        {gemTab === 'orderHistory' && <PatientOrderHistory />}
                        {gemTab === 'consults' && (
                            <ConsultList patient={patient} standalonePage={false} />
                        )}
                        {gemTab === 'careteam' && <CareTeam patient={patient} />}
                        {gemTab === 'insurance' && <PatientInsurance patient={patient} />}
                        {gemTab === 'documents' && <DocumentsView patient={patient} />}
                        {gemTab === 'resupply' && <PatientResupply patient={patient} />}
                    </div>
                )
            )}
        </AnimatePresence>
    );
};

export default Patient;
