import { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { formatDate } from '../../../utils/constants';
import { useCreateEstimateMutation } from '../../../app/services/admin/orders';

//Images
import { ReactComponent as CheckCircle } from '../../../images/icons/check-circle.svg';
import { ReactComponent as Download } from '../../../images/icons/download.svg';

//Components
import UploadFile from '../../UploadFile/UploadFile';
import Modal from '../../Modal/Modal';

const fileTypesMap = new Map([
    ['UPLOADED_SLEEP_STUDY', 'Uploaded Sleep Study'],
    ['UPLOADED_PRESCRIPTION', 'Uploaded Prescription'],
    ['UPLOADED_SLEEP_STUDY_WITH_PRESCRIPTION', 'Uploaded Sleep Study With Prescription'],
    ['ITAMAR_SLEEP_STUDY', 'Itamar Sleep Study'],
    ['REFERRAL', 'Written Order (Referral) Document'],
    ['PRESCRIPTION', 'Prescription'],
    ['INSURANCE_CARD_FRONT', 'Insurance Card Front'],
    ['INSURANCE_CARD_BACK', 'Insurance Card Back'],
    ['OTHER', 'Other Document'],
    ['CLINICAL_CHARTS', 'Clinical Charts'],
    ['ESTIMATE', 'Estimate'],
]);

const downloadBaseURL = process.env.REACT_APP_API_BASE_URL
    ? `${process.env.REACT_APP_API_BASE_URL}/files`
    : '/files';

function OrderEstimate({ order }) {
    //Redux state values
    const { storageToken } = useSelector((state) => state.auth);

    //Local state
    const [uploadModalOpen, setUploadModalOpen] = useState(false);

    //Queries and Mutations
    const [createEstimate] = useCreateEstimateMutation();

    const estimateFile = order?.estimate?.files[0];

    if (estimateFile) {
        return (
            <div className="col-span-4 space-y-2 px-4 py-2 rounded border border-[#ffffff0d] [box-shadow:0_0_3px_rgba(0,_0,_0,_0.5)] bg-[#ffffff0d]">
                <div className="flex justify-between items-center">
                    <p className="font-heading text-3xl">
                        <span className="text-gray mr-2">{estimateFile?.id}</span>
                        {fileTypesMap.get(estimateFile?.fileType)}
                    </p>
                    <div className="flex items-center space-x-4">
                        <a
                            className="border-white-500 inline-flex items-center rounded border bg-transparent py-1 px-3 text-base hover:bg-gray-darker"
                            href={`${downloadBaseURL}/${estimateFile?.id}?storageToken=${storageToken}`}
                            download
                            target="_blank"
                            rel="noreferrer">
                            Download
                            <Download className="ml-2" />
                        </a>
                    </div>
                </div>
                <div className="grid grid-cols-[250px] md:grid-cols-[250px_250px] xl:grid-cols-[250px_250px_250px_100px] gap-x-2">
                    <p className="flex">
                        <label className="w-[100px] font-bold">Filename:</label>
                        <div
                            className={`daisy-tooltip daisy-tooltip-right text-left flex items-center`}
                            data-tip={estimateFile?.fileName}>
                            <span className="w-[160px] opacity-80 whitespace-nowrap overflow-hidden text-ellipsis">
                                {estimateFile?.fileName}
                            </span>
                        </div>
                    </p>
                    <p className="flex">
                        <label className="w-[100px] font-bold">Created At:</label>
                        <span className="opacity-80">
                            {estimateFile?.createdAt && formatDate(estimateFile?.createdAt)}
                        </span>
                    </p>
                    <p className="flex">
                        <label className="w-[100px] font-bold">File Status:</label>
                        <span className="opacity-80">{estimateFile?.status}</span>
                        {estimateFile?.status === 'APPROVED' && (
                            <CheckCircle className="text-gem-green ml-2" />
                        )}
                    </p>
                    <p className="flex">
                        <label className="w-[60px] font-bold">Notes:</label>
                        {estimateFile?.notes && <CheckCircle />}
                    </p>
                </div>
            </div>
        );
    } else {
        return (
            <>
                <button
                    className="btn-shell-small hover:bg-gray col-span-2"
                    onClick={() => setUploadModalOpen(true)}>
                    Upload Estimate
                </button>
                <Modal
                    showClose={true}
                    isOpen={uploadModalOpen}
                    closeHandler={() => setUploadModalOpen(false)}>
                    <div>
                        <h2 className="mb-4 text-3xl font-bold">Upload Estimate</h2>
                        <div className="mt-6">
                            <UploadFile
                                isModal={true}
                                fileType="ESTIMATE"
                                patientId={order?.userId}
                                uploadButtonOverride="Upload Estimate"
                                uploadSuccessCallback={async (fileId) => {
                                    const estimateCreated = await createEstimate({
                                        signedId: fileId,
                                        status: 'IN_REVIEW',
                                        orderId: order?.id,
                                    });

                                    if (estimateCreated) {
                                        toast.success('Estimate created');
                                        window.setTimeout(() => {
                                            setUploadModalOpen(false);
                                        }, 2000);
                                    } else {
                                        toast.error('Something went wrong');
                                    }
                                }}
                            />
                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}

export default OrderEstimate;
