import { useState } from 'react';
import { Link } from 'react-router-dom';
import { A11y } from 'swiper/modules';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { TiArrowRight, TiArrowLeft } from 'react-icons/ti';
import { FiArrowUpRight } from 'react-icons/fi';
import { SupportCard } from '..';
import 'swiper/css';

const SliderControls = ({ disabledButton }) => {
    const swiper = useSwiper();
    return (
        <nav className="flex items-center space-x-6">
            <button
                className="disabled:opacity-25"
                onClick={() => {
                    swiper.slidePrev();
                }}
                disabled={disabledButton === 'prev'}>
                <TiArrowLeft className="h-6 w-6" />
            </button>
            <button
                className="disabled:opacity-25"
                onClick={() => {
                    swiper.slideNext();
                }}
                disabled={disabledButton === 'next'}>
                <TiArrowRight className="h-6 w-6" />
            </button>
        </nav>
    );
};

const ViewAllButton = ({ to }) => (
    <Link
        to={to}
        className="group flex items-center font-heading text-sm font-bold tracking-widest">
        View all
        <span className="relative ml-3 inline-block h-8 w-8 rounded-full bg-green-400 transition group-hover:scale-125">
            <FiArrowUpRight className="absolute-center h-3 w-3 scale-150 text-white transition" />
        </span>
    </Link>
);

const ArticlesSlider = ({
    slidesPerViewDesktop = 4,
    label,
    className,
    slidesPerView = 1.1,
    gap = 20,
    buttonType = 'controls',
    viewAllLink,
    slides = [],
}) => {
    //Local state
    const [disabledButton, setDisabledButton] = useState('prev');
    return (
        <div className={className}>
            {slides.length > 0 ? (
                <Swiper
                    onSlideChange={(swiper) => {
                        if (swiper.isBeginning) {
                            setDisabledButton('prev');
                            return;
                        }
                        if (swiper.isEnd) {
                            setDisabledButton('next');
                            return;
                        }
                        if (
                            swiper.activeIndex > 0 &&
                            swiper.activeIndex < swiper.slides.length - 1
                        ) {
                            setDisabledButton(null);
                            return;
                        }
                    }}
                    modules={[A11y]}
                    slidesPerView={slidesPerView}
                    spaceBetween={15}
                    centeredSlides={false}
                    breakpoints={{
                        640: {
                            slidesPerView: slidesPerViewDesktop,
                            spaceBetween: gap,
                        },
                    }}>
                    <div className="mb-4 flex items-center justify-between" slot="container-start">
                        <p className="font-semibold">{label}</p>
                        {buttonType === 'controls' && slides.length > slidesPerViewDesktop && (
                            <SliderControls disabledButton={disabledButton} />
                        )}
                        {buttonType === 'viewAll' && <ViewAllButton to={viewAllLink} />}
                    </div>
                    {slides.map((blogPost, idx) =>
                        Object.hasOwn(blogPost, 'image') ? (
                            <SwiperSlide key={`support-card-external`} className="h-auto">
                                <SupportCard
                                    imageUrl={blogPost.image}
                                    to={blogPost.url}
                                    title={blogPost.name}
                                />
                            </SwiperSlide>
                        ) : (
                            <SwiperSlide key={`support-card-${blogPost._id}`} className="h-auto">
                                <SupportCard
                                    imageUrl={blogPost['main-image']?.url}
                                    data={blogPost}
                                    to={`/dashboard/resources/blog/${blogPost.slug}`}
                                    title={blogPost.name}
                                    type={blogPost.video ? 'video' : 'article'}
                                />
                            </SwiperSlide>
                        ),
                    )}
                </Swiper>
            ) : (
                'No slides.'
            )}
        </div>
    );
};
export default ArticlesSlider;
