import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
    CreateOrganization,
    OrganizationList,
    UpdateOrganization,
} from '../../components/Admin/Organizations';

function Organizations() {
    const navigate = useNavigate();
    const { orgTab } = useParams();

    useEffect(() => {
        if (!orgTab) {
            navigate('list', { replace: true });
        }
    }, [navigate, orgTab]);

    return (
        <>
            <h2 className="mb-5 pt-6 flex items-center justify-between">
                <span className="font-heading text-5xl">Organizations</span>
                <Link
                    to="/admin/organizations/create"
                    className="border-b-2 border-gem-green font-bold">
                    Add a new organization
                </Link>
            </h2>
            {orgTab === 'list' && <OrganizationList />}
            {orgTab === 'create' && <CreateOrganization />}
            {orgTab === 'update' && <UpdateOrganization />}
        </>
    );
}

export default Organizations;
